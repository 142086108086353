import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import style2 from "../../style2.css"
import Select from 'react-select'
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class S_stock_out_wastage extends Component {

    constructor() {
        super();
        this.state = {
            sum_by: "-1",
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            obj: [],
            objload: false,

        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        this.setState({})
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_category_for_stock_summary;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    categories: res.data.datas,
                    category_selected: {label: "All", value: "All"},
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

        baseurl = myconfig.get_warehouse_for_stock_summary;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: "All",
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    warehouses: res.data.datas,
                    warehouse_selected: null,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    btnexport = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Summary Stock Out Wastage");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report_1'));
        wb.Sheets["Summary Stock Out Wastage"] = wb2;
        XLSX.writeFile(wb, "summary_stock_out_wastage.xlsx");
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            obj: [],
            objload: false,
        })
    }

    form_report_criteria = () => {
        let retdata = <div className="col-12">
            <div className="card">
                <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Report Criteria</h3>
                    <div className="card-tools">
                    </div>
                </div>
                <form>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Category </label>
                                    <Select
                                        value={this.state.category_selected}
                                        onChange={this.handelchange_category}
                                        options={this.state.categories}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Warehouse</label>
                                    <Select
                                        value={this.state.warehouse_selected}
                                        onChange={this.handelchange_warehouse}
                                        options={this.state.warehouses}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>From</label>
                                    <input type="date" name="st" value={this.state.st} onChange={this.inputmgt}
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>To</label>
                                    <input type="date" name="ed" value={this.state.ed} onChange={this.inputmgt}
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                            </div>
                        </div>
                    </div>
                </form>
                <button onClick={this.btn_get_report} type="button" className="btn btn-block btn-primary">Get Report
                </button>
            </div>
        </div>
        return (<>{retdata}</>)
    }
    handelchange_category = (category_selected) => {
        this.setState({
            category_selected: category_selected,
        })
    }
    handelchange_warehouse = (warehouse_selected) => {
        this.setState({
            warehouse_selected: warehouse_selected,
            obj: [],
            objload: false,
        })
    }
    btn_get_report = (event) => {

        if (this.state.category_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Category required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.warehouse_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Warehouse is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            event.preventDefault()
            this.setState({
                obj: [],
                objload: false,
            })
            let baseurl = myconfig.get_s_stock_out_wastage;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                category_id: this.state.category_selected.value,
                warehouse_id: this.state.warehouse_selected.value,
                st: this.state.st,
                ed: this.state.ed,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        obj: res.data,
                        objload: true,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    tabledata = () => {
        let retdata = null;
        if (this.state.objload) {
            let totalprice=0;
            let totalitem=0;
            let tabledata = this.state.obj.datas.map(v => {
                totalitem=totalitem+parseFloat(v.wastage_qty)
                totalprice=totalprice+parseFloat(v.w_price)
                return <tr>
                    <td>{v.category_name}</td>
                    <td>{v.warehouse}</td>
                    <td>{v.spec}</td>
                    <td>{v.expire_date}</td>
                    <td>{v.price}</td>
                    <td>{v.app_qty}</td>
                    <td>{v.req_by}</td>
                    <td>{v.wastage_at}</td>
                    <td>{v.wastage_qty}</td>
                    <td>{v.w_price}</td>
                    <td>{v.wastage_remark}</td>
                </tr>
            });
            retdata = <>
                <div className="invoice p-3 mb-3">
                    <div id="section_to_print">
                        <div className="row">
                            <div className="col-12">
                                <h4>
                                    <i className="fas fa-globe"/> Guansa, PLC.
                                    <small className="float-right">Date: {this.state.obj.dt}</small>
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 table-responsive">
                                <table id="report_1" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th className="col-md-1">Category Name</th>
                                        <th className="col-md-1">Warehouse Name</th>
                                        <th className="col-md-1">Spec</th>
                                        <th className="col-md-1">Expire Date</th>
                                        <th className="col-md-1">Price</th>
                                        <th className="col-md-1">Req QTY</th>
                                        <th className="col-md-1">Req BY</th>
                                        <th className="col-md-1">Wastage At</th>
                                        <th className="col-md-1">Wastage Qty</th>
                                        <th className="col-md-1">Wastage Price</th>
                                        <th className="col-md-2">Wastage Remark</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tabledata}
                                    <tr>
                                        <td colSpan={11} className="text-right"><b><u>Total Items: </u></b>{totalitem}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={11} className="text-right"><b><u>Total Wastage Price: </u></b>{totalprice}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <a href="" onClick={event => {
                                event.preventDefault();
                                window.print()
                            }} className="btn btn-default"><i className="fas fa-print"/> Print</a>
                            <button onClick={this.btnexport} type="button" className="btn btn-primary float-right"
                                    style={{marginRight: '5px'}}>
                                <i className="fas fa-download"/> Generate xlsx
                            </button>
                        </div>
                    </div>
                </div>

            </>
        }
        return (<>{retdata}</>)
    }

    render() {
        return (
            <>
                <Nav type="s_stock_out_wastage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock Out Wastage Summary</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Summary</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {this.form_report_criteria()}
                                </div>
                                <div className="col-12">
                                    <div className="callout callout-info">
                                        <h5><i className="fas fa-info"/> Stock Out Wastage Summary </h5>
                                        This page has been enhanced for printing. Click the print button at the bottom
                                        of the report.
                                    </div>
                                    {this.tabledata()}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default S_stock_out_wastage;
