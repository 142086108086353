import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Approval_role extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            objsload: false,
            filtered: [],
            users: [],
            txtsearch: '',

            username:'',
            form_main_display: false,
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        this.setState({
            objs: [],
            objsload: false,
            filtered: [],

            id: -1,
            txtsearch: '',
            form_main_display: false,
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_user_approval;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered: res.data.datas,
                    objsload: true,

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    hideform = (event) => {
        event.preventDefault()
        this.setState({
            form_main_display: false,
            form_fileds_display: false,
        })
    }

    showform_role = (event, id = -1) => {
        event.preventDefault()
        this.setState({
            username:'',
            form_main_display: false,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_user_approval_single;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        id: id,
                        username: res.data.username,
                        users: res.data.datas,
                        form_main_display: true,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });

        })
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    user_select = (event) => {
        var options = event.target.options;
        var temp=this.state.users;
        temp=temp.map(value=>{
            value.selected=false;
            return value;
        })
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                temp = temp.map(v => {
                    if (v.id == options[i].value)
                        v.selected = true;
                    return v;
                })
            }
        }
        this.setState({
            users: temp,
        })
        console.log(value);
    }
    form_role= () => {
        let retdata = null;
        if (this.state.form_main_display) {
            retdata = <div className="col-12">
                <div className="card">
                    <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                        <h3 className="card-title">User role management console</h3>
                        <div className="card-tools">
                            <button type="button" onClick={this.hideform} className="btn btn-tool">
                                <i className="fas fa-times"/>
                            </button>
                        </div>
                    </div>
                    <form>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input disabled={true} className="form-control" type="text"
                                                   name="username"
                                                   value={this.state.username}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Users</label>
                                            <select onChange={this.user_select} multiple={true} className="form-control">
                                                {this.user_option()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button onClick={this.btn_role_update} type="button" className="btn btn-block btn-primary">Role Update
                    </button>
                </div>
            </div>
        }
        return (<>{retdata}</>)
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    user_option = () => {
        let retdata=this.state.users.map(value=>{
            return( <option selected={value.selected} value={value.id}>{value.name}</option>)
        })
        return <>{retdata}</>
    }
    btn_role_update = (event) => {
        event.preventDefault()
        let baseurl = myconfig.update_approval_role;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: this.state.id,
            users:this.state.users,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    tabledata = () => {
        let retdata = null;
        if (this.state.objsload) {
            let count = 0;
            let manage = null;
            retdata = this.state.filtered.map(value => {
                count = count + 1;
                if (this.state.objs.usermatrix.update == true)
                    manage = <td className="col-md-1"><a href=""
                                                      onClick={event => this.showform_role(event, value.id)}>Manage</a>
                    </td>
                return (<tr>
                    <td>{count}</td>
                    {manage}
                    <td>{value.name}</td>
                    <td>{value.username}</td>
                    <td>{value.allowed_users}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    render() {
        let manage = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.update == true)
                manage = <th className="col-md-2">Manage</th>
        }
        return (
            <>
                <Nav type="approval_role"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Approval Role Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Approval Role</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {this.form_role()}
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed ">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-1">#</th>
                                                    {manage}
                                                    <th className="col-md-2">Username</th>
                                                    <th className="col-md-2">Full Name</th>
                                                    <th className="col-md-6">Allowed User</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Approval_role;
