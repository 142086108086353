import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Balance_dashboard extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            datas: [],
            rows: [],
            filters: [],
            category_selected: null,
            stock_item_selected: null,
            warehouse_selected: null,
            form_visble: false,
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        this.setState({})
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_category_for_dashboard;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    categories: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
        baseurl = myconfig.get_warehouse_for_dashboard;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: "All",
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    warehouses: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetcheddata2 = () => {
        this.setState({
            datas: [],
            rows: [],
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_balance_for_dashboard;
        let category_selected = this.state.category_selected == null ? null : this.state.category_selected.value;
        let warehouse_selected = this.state.warehouse_selected == null ? null : this.state.warehouse_selected.value;
        let stock_item_selected = this.state.stock_item_selected == null ? null : this.state.stock_item_selected.value;
        let filed_1 = this.state.filed_1 == null ? null : this.state.filed_1.value;
        let filed_2 = this.state.filed_2 == null ? null : this.state.filed_2.value;
        let filed_3 = this.state.filed_3 == null ? null : this.state.filed_3.value;
        let filed_4 = this.state.filed_4 == null ? null : this.state.filed_4.value;
        let filed_5 = this.state.filed_5 == null ? null : this.state.filed_5.value;
        let filed_6 = this.state.filed_6 == null ? null : this.state.filed_6.value;
        let filed_7 = this.state.filed_7 == null ? null : this.state.filed_7.value;
        let filed_8 = this.state.filed_8 == null ? null : this.state.filed_8.value;
        let filed_9 = this.state.filed_9 == null ? null : this.state.filed_9.value;
        let filed_10 = this.state.filed_10 == null ? null : this.state.filed_10.value;
        let filed_11 = this.state.filed_11 == null ? null : this.state.filed_11.value;
        let filed_12 = this.state.filed_12 == null ? null : this.state.filed_12.value;
        let filed_13 = this.state.filed_13 == null ? null : this.state.filed_13.value;
        let filed_14 = this.state.filed_14 == null ? null : this.state.filed_14.value;
        let filed_15 = this.state.filed_15 == null ? null : this.state.filed_15.value;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            category: category_selected,
            warehouse: warehouse_selected,
            stockitem: stock_item_selected,
            filed_1: filed_1,
            filed_2: filed_2,
            filed_3: filed_3,
            filed_4: filed_4,
            filed_5: filed_5,
            filed_6: filed_6,
            filed_7: filed_7,
            filed_8: filed_8,
            filed_9: filed_9,
            filed_10: filed_10,
            filed_11: filed_11,
            filed_12: filed_12,
            filed_13: filed_13,
            filed_14: filed_14,
            filed_15: filed_15,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    rows: res.data.rows,
                    filters: res.data.filters,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    handelchange_category = (category_selected) => {
        this.setState({
            stock_items: [],
            stock_item_selected: null,
            form_visble: false,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_items_for_dashboard;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: category_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        stock_items: res.data.datas,
                        category_selected: category_selected,
                    }, () => this.fetcheddata2())
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    handelchange_warehouse = (warehouse_selected) => {
        let vi = true;
        if (this.state.stock_item_selected.value == "All")
            vi = false;
        this.setState({
            warehouse_selected: warehouse_selected,
            obj: [],
            objload: false,
            form_visble: vi,
        }, () => this.fetcheddata2())
    }
    handelchange_stock_item = (stock_item_selected) => {
        let vi = true;
        if (stock_item_selected.value == "All" || this.state.warehouse_selected == null)
            vi = false;
        this.setState({
            stock_item_selected: stock_item_selected,
            obj: [],
            objload: false,
            form_visble: vi,
            filed_1: {label: "All", value: "All"},
            filed_2: {label: "All", value: "All"},
            filed_3: {label: "All", value: "All"},
            filed_4: {label: "All", value: "All"},
            filed_5: {label: "All", value: "All"},
            filed_6: {label: "All", value: "All"},
            filed_7: {label: "All", value: "All"},
            filed_8: {label: "All", value: "All"},
            filed_9: {label: "All", value: "All"},
            filed_10: {label: "All", value: "All"},
            filed_11: {label: "All", value: "All"},
            filed_12: {label: "All", value: "All"},
            filed_13: {label: "All", value: "All"},
            filed_14: {label: "All", value: "All"},
            filed_15: {label: "All", value: "All"},
        }, () => this.fetcheddata2())
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            obj: [],
            objload: false,
        })
    }
    form_report_criteria = () => {
        let retdata = <div className="col-12">
            <div className="card">
                <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Filter</h3>
                    <div className="card-tools">
                    </div>
                </div>
                <form>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Category </label>
                                    <Select
                                        value={this.state.category_selected}
                                        onChange={this.handelchange_category}
                                        options={this.state.categories}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Stock Item</label>
                                    <Select
                                        value={this.state.stock_item_selected}
                                        onChange={this.handelchange_stock_item}
                                        options={this.state.stock_items}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Warehouse</label>
                                    <Select
                                        value={this.state.warehouse_selected}
                                        onChange={this.handelchange_warehouse}
                                        options={this.state.warehouses}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        return (<>{retdata}</>)
    }
    form_fileds_criteria = () => {
        let retdata = null;
        if (this.state.form_visble) {
            let d = this.state.filters.map(v => {
                console.log(this.state[v.name])
                return (
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>{v.label}</label>
                            <Select
                                id={v.label}
                                value={this.state[v.name]}
                                onChange={sv => {
                                    this.setState({
                                        [v.name]: sv,
                                    }, () => this.fetcheddata2())
                                }}
                                options={v.datas}
                            />
                        </div>
                    </div>
                )
            });
            retdata = <div className="col-12">
                <div className="card">
                    <div className="card-header bg-fuchsia ui-sortable-handle" style={{cursor: 'move'}}>
                        <h3 className="card-title">Fields Filter</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <form>
                        <div className="card-body">
                            <div className="row">
                                {d}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        }
        return (<>{retdata}</>)
    }
    tabledata = () => {
        let header = this.state.rows.map(v => {
            return <th className="col-md-1">{v.name}</th>
        })
        let datas = this.state.datas.map(v => {
            let datas2 = v.rows.map(v2 => {
                return <td>{v2.data}</td>
            })
            return <tr>{datas2}</tr>
        })
        let retdata = <table className="table">
            <thead>
            {header}
            </thead>
            <tbody>
            {datas}
            </tbody>
        </table>;
        return (<>{retdata}</>)
    }

    render() {
        return (
            <>
                <Nav type="balance_dashboard"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock Balance</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Dashboard_4</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {this.form_report_criteria()}
                                    {this.form_fileds_criteria()}
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Stock Balance</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            {this.tabledata()}
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Balance_dashboard;
