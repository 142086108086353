import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class R_stock_in_wastage extends Component {

    constructor() {
        super();
        this.state = {
            sum_by:"-1",
            st:new Date().toISOString().slice(0,10),
            ed:new Date().toISOString().slice(0,10),
            obj:[],
            objload:false,
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        this.setState({
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_category_for_report;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    categories: res.data.datas,
                    category_selected: {label:"All",value:"All"},
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
        baseurl = myconfig.get_stock_items_for_report;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: "All",
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    stock_items: res.data.datas,
                    stock_item_selected: {label:"All",value:"All"},
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

        baseurl = myconfig.get_warehouse_for_report_2;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: "All",
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    warehouses: res.data.datas,
                    warehouse_selected: {label:"All",value:"All"},
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    handelchange_category = (category_selected) => {
        this.setState({
            stock_items: [],
            stock_item_selected: null,
            obj:[],
            objload:false,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_items_for_report;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: category_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        stock_items: res.data.datas,
                        category_selected: category_selected,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            obj:[],
            objload:false,
        })
    }
    form_report_criteria = () => {
        let retdata = <div className="col-12">
            <div className="card">
                <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Report Criteria</h3>
                    <div className="card-tools">
                    </div>
                </div>
                <form>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Category </label>
                                    <Select
                                        value={this.state.category_selected}
                                        onChange={this.handelchange_category}
                                        options={this.state.categories}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Stock Item</label>
                                    <Select
                                        value={this.state.stock_item_selected}
                                        onChange={this.handelchange_stock_item}
                                        options={this.state.stock_items}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Warehouse</label>
                                    <Select
                                        value={this.state.warehouse_selected}
                                        onChange={this.handelchange_warehouse}
                                        options={this.state.warehouses}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>Summary By</label>
                                    <select name="sum_by" value={this.state.sum_by} onChange={this.inputmgt} className="form-control">
                                        <option value="-1">--Select Summary By--</option>
                                        <option value="1">Category</option>
                                        <option value="2">Item Name</option>
                                        <option value="3">Item Fields</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>From</label>
                                    <input type="date" name="st" value={this.state.st} onChange={this.inputmgt} className="form-control"/>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>To</label>
                                    <input type="date" name="ed" value={this.state.ed} onChange={this.inputmgt} className="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <button onClick={this.btn_get_report} type="button" className="btn btn-block btn-primary">Get Report
                </button>
            </div>
        </div>
        return (<>{retdata}</>)
    }
    handelchange_stock_item = (stock_item_selected) => {
        this.setState({
            stock_item_selected: stock_item_selected,
            obj:[],
            objload:false,
        })
    }
    handelchange_warehouse = (warehouse_selected) => {
        this.setState({
            warehouse_selected: warehouse_selected,
            obj:[],
            objload:false,
        })
    }
    btn_get_report = (event) => {
        if (this.state.category_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Category required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.stock_item_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Stock Item is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

        else if (this.state.warehouse_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Warehouse is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.sum_by == "-1") {
            Swal.fire({
                title: 'Error!',
                text: "Summury by is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

        else
        {
            event.preventDefault()
            this.setState({
                obj:[],
                objload:false,
            })
            let baseurl = myconfig.get_r_stock_in_wastage;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                category_id: this.state.category_selected.value,
                stock_item_id: this.state.stock_item_selected.value,
                warehouse_id: this.state.warehouse_selected.value,
                sum_by: this.state.sum_by,
                st: this.state.st,
                ed: this.state.ed,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        obj:res.data,
                        objload:true,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    tabledata = () => {
        let retdata = null;
        if (this.state.objload) {
            let count = 0;
            let app = null;
            let rows = this.state.obj.rows.map(v => {
                return <th className="col-md-1">{v.name}</th>
            });
            let datas = this.state.obj.reports.map(v => {
                count=count+1;
                if(this.state.sum_by==2)
                    return <tr>
                        <td>{count}</td>
                        <td>{v.item_name}</td>
                        <td>{v.cat_name}</td>
                        <td>{v.warehouse_name}</td>
                        <td>{v.app_qty}</td>
                        <td>{v.wastage_qty}</td>
                        <td>{v.wastage_price}</td>
                    </tr>
                else if(this.state.sum_by==3) {
                    let filed_1=v.filed_1==null?null:<td>{v.filed_1}</td>
                    let filed_2=v.filed_2==null?null:<td>{v.filed_2}</td>
                    let filed_3=v.filed_3==null?null:<td>{v.filed_3}</td>
                    let filed_4=v.filed_4==null?null:<td>{v.filed_4}</td>
                    let filed_5=v.filed_5==null?null:<td>{v.filed_5}</td>
                    let filed_6=v.filed_6==null?null:<td>{v.filed_6}</td>
                    let filed_7=v.filed_7===null?null:<td>{v.filed_7}</td>
                    let filed_8=v.filed_8==null?null:<td>{v.filed_8}</td>
                    let filed_9=v.filed_9==null?null:<td>{v.filed_9}</td>
                    let filed_10=v.filed_10==null?null:<td>{v.filed_10}</td>
                    let filed_11=v.filed_11==null?null:<td>{v.filed_11}</td>
                    let filed_12=v.filed_12==null?null:<td>{v.filed_12}</td>
                    let filed_13=v.filed_13==null?null:<td>{v.filed_13}</td>
                    let filed_14=v.filed_14==null?null:<td>{v.filed_14}</td>
                    let filed_15=v.filed_15==null?null:<td>{v.filed_15}</td>
                    return <tr>
                        <td>{count}</td>
                        <td>{v.item_name}</td>
                        <td>{v.cat_name}</td>
                        {filed_1}
                        {filed_2}
                        {filed_3}
                        {filed_4}
                        {filed_5}
                        {filed_6}
                        {filed_7}
                        {filed_8}
                        {filed_9}
                        {filed_10}
                        {filed_11}
                        {filed_12}
                        {filed_13}
                        {filed_14}
                        {filed_15}
                        <td>{v.warehouse_name}</td>
                        <td>{v.app_qty}</td>
                        <td>{v.wastage_qty}</td>
                        <td>{v.wastage_price}</td>
                    </tr>
                }
                else
                    return <tr>
                        <td>{count}</td>
                        <td>{v.cat_name}</td>
                        <td>{v.warehouse_name}</td>
                        <td>{v.app_qty}</td>
                        <td>{v.wastage_qty}</td>
                        <td>{v.wastage_price}</td>
                    </tr>
            });
            retdata = <table className="table">
                <thead>
                <tr>
                    {rows}
                </tr>
                </thead>
                <tbody>
                {datas}
                </tbody>
            </table>
        }
        return (<>{retdata}</>)
    }

    render() {
        return (
            <>
                <Nav type="r_stock_in_wastage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock Wastage In Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {this.form_report_criteria()}
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Stock Wastage In Report</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            {this.tabledata()}
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default R_stock_in_wastage;
