export const myconfig =
    {
            "getusers": "https://api.ims.guansaplc.com/user/getusers",
            "getuser": "https://api.ims.guansaplc.com/user/getuser",
            "createuser": "https://api.ims.guansaplc.com/user/createuser",
            "updateuser": "https://api.ims.guansaplc.com/user/updateuser",
            "changepwduser": "https://api.ims.guansaplc.com/user/changepwduser",
            "changepwd_by_user": "https://api.ims.guansaplc.com/user/changepwd_by_user",

            "getobjusers": "https://api.ims.guansaplc.com/objuser/getobjusers",
            "getobjuser": "https://api.ims.guansaplc.com/objuser/getobjuser",
            "createobjuser": "https://api.ims.guansaplc.com/objuser/createobjuser",
            "updateobjuser": "https://api.ims.guansaplc.com/objuser/updateobjuser",


            "getusers_objusers": "https://api.ims.guansaplc.com/userobj/getusers_objusers",
            "getuseraccess": "https://api.ims.guansaplc.com/userobj/getuseraccess",
            "updateuseraccess": "https://api.ims.guansaplc.com/userobj/updateuseraccess",



            "getuasession": "https://api.ims.guansaplc.com/user/getuasession",
            "login": "https://api.ims.guansaplc.com/user/login",
            "checksession": "https://api.ims.guansaplc.com/user/checksession",
            "logout": "https://api.ims.guansaplc.com/user/logout",

            "get_siunits": "https://api.ims.guansaplc.com/api/managment/get_siunits",
            "get_siunit": "https://api.ims.guansaplc.com/api/managment/get_siunit",
            "create_siunit": "https://api.ims.guansaplc.com/api/managment/create_siunit",
            "update_siunit": "https://api.ims.guansaplc.com/api/managment/update_siunit",

            "get_categories": "https://api.ims.guansaplc.com/api/managment/get_categories",
            "get_category": "https://api.ims.guansaplc.com/api/managment/get_category",
            "create_category": "https://api.ims.guansaplc.com/api/managment/create_category",
            "update_category": "https://api.ims.guansaplc.com/api/managment/update_category",

            "get_warehouses": "https://api.ims.guansaplc.com/api/managment/get_warehouses",
            "get_warehouse": "https://api.ims.guansaplc.com/api/managment/get_warehouse",
            "create_warehouse": "https://api.ims.guansaplc.com/api/managment/create_warehouse",
            "update_warehouse": "https://api.ims.guansaplc.com/api/managment/update_warehouse",

            "get_stock_items_obj": "https://api.ims.guansaplc.com/api/managment/get_stock_items_obj",
            "get_stock_items": "https://api.ims.guansaplc.com/api/managment/get_stock_items",
            "get_stock_item": "https://api.ims.guansaplc.com/api/managment/get_stock_item",
            "create_stock_item": "https://api.ims.guansaplc.com/api/managment/create_stock_item",


            "get_category_stock_req": "https://api.ims.guansaplc.com/api/stockin/get_category_stock_req",
            "get_stock_items_stock_req": "https://api.ims.guansaplc.com/api/stockin/get_stock_items_stock_req",
            "get_stock_items_fileds_stock_req": "https://api.ims.guansaplc.com/api/stockin/get_stock_items_fileds_stock_req",
            "get_warehouse_stock_req": "https://api.ims.guansaplc.com/api/stockin/get_warehouse_stock_req",
            "create_stock_in_req": "https://api.ims.guansaplc.com/api/stockin/create_stock_in_req",
            "get_stock_in_req_items": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_req_items",
            "get_stock_in_req_item": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_req_item",
            "update_stock_in_req": "https://api.ims.guansaplc.com/api/stockin/update_stock_in_req",
            "delete_stock_in_req": "https://api.ims.guansaplc.com/api/stockin/delete_stock_in_req",
            "get_stock_in_app_items": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_app_items",
            "get_stock_in_app_item": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_app_item",
            "approval_stock_in": "https://api.ims.guansaplc.com/api/stockin/approval_stock_in",
            "get_stock_in_rec_items": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_rec_items",
            "get_stock_in_rec_item": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_rec_item",
            "receive_stock_in": "https://api.ims.guansaplc.com/api/stockin/receive_stock_in",
            "get_stock_in_daily_items": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_daily_items",
            "get_stock_in_daily_item": "https://api.ims.guansaplc.com/api/stockin/get_stock_in_daily_item",

            "get_category_stock_out_req": "https://api.ims.guansaplc.com/api/stockout/get_category_stock_out_req",
            "get_stock_items_stock_out_req": "https://api.ims.guansaplc.com/api/stockout/get_stock_items_stock_out_req",
            "get_warehouse_stock_out_req": "https://api.ims.guansaplc.com/api/stockout/get_warehouse_stock_out_req",
            "get_stock_item_balance_by_stock_warehouse_stock_out": "https://api.ims.guansaplc.com/api/stockout/get_stock_item_balance_by_stock_warehouse_stock_out",
            "get_stock_item_balance_by_fileds_stock_out": "https://api.ims.guansaplc.com/api/stockout/get_stock_item_balance_by_fileds_stock_out",
            "create_stock_out_req": "https://api.ims.guansaplc.com/api/stockout/create_stock_out_req",
            "get_stock_out_req_items": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_req_items",
            "get_stock_out_req_item": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_req_item",
            "delete_stock_out_req": "https://api.ims.guansaplc.com/api/stockout/delete_stock_out_req",
            "get_stock_out_app_items": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_app_items",
            "get_stock_out_app_item": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_app_item",
            "approval_stock_out": "https://api.ims.guansaplc.com/api/stockout/approval_stock_out",
            "get_stock_out_rec_items": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_rec_items",
            "get_stock_out_rec_item": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_rec_item",
            "receive_stock_out": "https://api.ims.guansaplc.com/api/stockout/receive_stock_out",
            "get_stock_out_daily_items": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_daily_items",
            "get_stock_out_daily_item": "https://api.ims.guansaplc.com/api/stockout/get_stock_out_daily_item",


            "get_category_stock_wastage_in": "https://api.ims.guansaplc.com/api/stockwastage/get_category_stock_wastage_in",
            "get_stock_items_stock_wastage_in": "https://api.ims.guansaplc.com/api/stockwastage/get_stock_items_stock_wastage_in",
            "get_warehouse_stock_wastage_in": "https://api.ims.guansaplc.com/api/stockwastage/get_warehouse_stock_wastage_in",
            "get_stock_in_for_wastage": "https://api.ims.guansaplc.com/api/stockwastage/get_stock_in_for_wastage",
            "get_stock_in_for_wastage_single": "https://api.ims.guansaplc.com/api/stockwastage/get_stock_in_for_wastage_single",
            "create_update_wastage_in": "https://api.ims.guansaplc.com/api/stockwastage/create_update_wastage_in",

            "get_category_stock_wastage_out": "https://api.ims.guansaplc.com/api/stockwastage/get_category_stock_wastage_out",
            "get_stock_items_stock_wastage_out": "https://api.ims.guansaplc.com/api/stockwastage/get_stock_items_stock_wastage_out",
            "get_warehouse_stock_wastage_out": "https://api.ims.guansaplc.com/api/stockwastage/get_warehouse_stock_wastage_out",
            "get_stock_out_for_wastage": "https://api.ims.guansaplc.com/api/stockwastage/get_stock_out_for_wastage",
            "get_stock_out_for_wastage_single": "https://api.ims.guansaplc.com/api/stockwastage/get_stock_out_for_wastage_single",
            "create_update_wastage_out": "https://api.ims.guansaplc.com/api/stockwastage/create_update_wastage_out",

            "get_category_stock_transfer": "https://api.ims.guansaplc.com/api/stocktransfer/get_category_stock_transfer",
            "get_stock_items_stock_transfer": "https://api.ims.guansaplc.com/api/stocktransfer/get_stock_items_stock_transfer",
            "get_stock_items_for_report_2": "https://api.ims.guansaplc.com/api/inventoryreport/get_stock_items_for_report_2",
            "get_warehouse_stock_transfer": "https://api.ims.guansaplc.com/api/stocktransfer/get_warehouse_stock_transfer",
            "get_stock_in_for_transfer": "https://api.ims.guansaplc.com/api/stocktransfer/get_stock_in_for_transfer",
            "get_stock_in_for_transfer_single": "https://api.ims.guansaplc.com/api/stocktransfer/get_stock_in_for_transfer_single",
            "transfer_stock_in": "https://api.ims.guansaplc.com/api/stocktransfer/transfer_stock_in",
            "get_stock_items_fileds_stock_transfer": "https://api.ims.guansaplc.com/api/stocktransfer/get_stock_items_fileds_stock_transfer",


            "get_category_for_report": "https://api.ims.guansaplc.com/api/inventoryreport/get_category_for_report",
            "get_stock_items_for_report": "https://api.ims.guansaplc.com/api/inventoryreport/get_stock_items_for_report",
            "get_warehouse_for_report_2": "https://api.ims.guansaplc.com/api/inventoryreport/get_warehouse_for_report_2",
            "get_warehouse_for_report": "https://api.ims.guansaplc.com/api/inventoryreport/get_warehouse_for_report",
            "get_r_inventory_summary": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_inventory_summary",
            "get_r_inventory_valuation": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_inventory_valuation",
            "get_r_fifo_cost_tracking": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_fifo_cost_tracking",
            "get_r_stock_summary": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_stock_summary",
            "get_r_stock_in": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_stock_in",
            "get_r_stock_out": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_stock_out",
            "get_users_for_report": "https://api.ims.guansaplc.com/api/inventoryreport/get_users_for_report",
            "get_r_stock_in_wastage": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_stock_in_wastage",
            "get_r_stock_out_wastage": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_stock_out_wastage",
            "get_r_stock_transfer": "https://api.ims.guansaplc.com/api/inventoryreport/get_r_stock_transfer",


            "get_category_for_dashboard": "https://api.ims.guansaplc.com/api/dashboard/get_category_for_dashboard",
            "get_stock_items_for_dashboard": "https://api.ims.guansaplc.com/api/dashboard/get_stock_items_for_dashboard",
            "get_warehouse_for_dashboard": "https://api.ims.guansaplc.com/api/dashboard/get_warehouse_for_dashboard",
            "get_balance_for_dashboard": "https://api.ims.guansaplc.com/api/dashboard/get_balance_for_dashboard",
            "get_inventory_dashboard": "https://api.ims.guansaplc.com/api/dashboard/get_inventory_dashboard",
            "get_transaction_d_dashboard": "https://api.ims.guansaplc.com/api/dashboard/get_transaction_d_dashboard",
            "get_transaction_m_dashboard": "https://api.ims.guansaplc.com/api/dashboard/get_transaction_m_dashboard",


            "get_user_warehouse": "https://api.ims.guansaplc.com/api/role/get_user_warehouse",
            "get_user_warehouse_single": "https://api.ims.guansaplc.com/api/role/get_user_warehouse_single",
            "update_warehouse_role": "https://api.ims.guansaplc.com/api/role/update_warehouse_role",
            "get_user_approval": "https://api.ims.guansaplc.com/api/role/get_user_approval",
            "get_user_approval_single": "https://api.ims.guansaplc.com/api/role/get_user_approval_single",
            "update_approval_role": "https://api.ims.guansaplc.com/api/role/update_approval_role",

            "get_daily_in": "https://api.ims.guansaplc.com/api/daily/get_daily_in",
            "get_daily_out": "https://api.ims.guansaplc.com/api/daily/get_daily_out",
            "get_daily_transfer": "https://api.ims.guansaplc.com/api/daily/get_daily_transfer",


            "get_category_for_stock_summary": "https://api.ims.guansaplc.com/api/summary/get_category_for_stock_summary",
            "get_stock_items_for_stock_summary": "https://api.ims.guansaplc.com/api/summary/get_stock_items_for_stock_summary",
            "get_warehouse_for_stock_summary": "https://api.ims.guansaplc.com/api/summary/get_warehouse_for_stock_summary",
            "get_s_stock_in": "https://api.ims.guansaplc.com/api/summary/get_s_stock_in",
            "get_s_stock_out": "https://api.ims.guansaplc.com/api/summary/get_s_stock_out",
            "get_s_stock_in_wastage": "https://api.ims.guansaplc.com/api/summary/get_s_stock_in_wastage",
            "get_s_stock_out_wastage": "https://api.ims.guansaplc.com/api/summary/get_s_stock_out_wastage",
            "get_s_stock_transfer": "https://api.ims.guansaplc.com/api/summary/get_s_stock_transfer",
            "get_s_stock_balance": "https://api.ims.guansaplc.com/api/summary/get_s_stock_balance",

            "redirecttologin": "https://ims.guansaplc.com/login",
            "redirect": "https://ims.guansaplc.com",

            /* "getusers": "http://localhost/tecnosmsapi/public/user/getusers",
             "getuser": "http://localhost/tecnosmsapi/public/user/getuser",
             "createuser": "http://localhost/tecnosmsapi/public/user/createuser",
             "updateuser": "http://localhost/tecnosmsapi/public/user/updateuser",
             "changepwduser": "http://localhost/tecnosmsapi/public/user/changepwduser",
             "changepwd_by_user": "http://localhost/tecnosmsapi/public/user/changepwd_by_user",

             "getobjusers": "http://localhost/tecnosmsapi/public/objuser/getobjusers",
             "getobjuser": "http://localhost/tecnosmsapi/public/objuser/getobjuser",
             "createobjuser": "http://localhost/tecnosmsapi/public/objuser/createobjuser",
             "updateobjuser": "http://localhost/tecnosmsapi/public/objuser/updateobjuser",


             "getusers_objusers": "http://localhost/tecnosmsapi/public/userobj/getusers_objusers",
             "getuseraccess": "http://localhost/tecnosmsapi/public/userobj/getuseraccess",
             "updateuseraccess": "http://localhost/tecnosmsapi/public/userobj/updateuseraccess",



             "getuasession": "http://localhost/tecnosmsapi/public/user/getuasession",
             "login": "http://localhost/tecnosmsapi/public/user/login",
             "checksession": "http://localhost/tecnosmsapi/public/user/checksession",
             "logout": "http://localhost/tecnosmsapi/public/user/logout",

             "get_siunits": "http://localhost/tecnosmsapi/public/api/managment/get_siunits",
             "get_siunit": "http://localhost/tecnosmsapi/public/api/managment/get_siunit",
             "create_siunit": "http://localhost/tecnosmsapi/public/api/managment/create_siunit",
             "update_siunit": "http://localhost/tecnosmsapi/public/api/managment/update_siunit",

             "get_categories": "http://localhost/tecnosmsapi/public/api/managment/get_categories",
             "get_category": "http://localhost/tecnosmsapi/public/api/managment/get_category",
             "create_category": "http://localhost/tecnosmsapi/public/api/managment/create_category",
             "update_category": "http://localhost/tecnosmsapi/public/api/managment/update_category",

             "get_warehouses": "http://localhost/tecnosmsapi/public/api/managment/get_warehouses",
             "get_warehouse": "http://localhost/tecnosmsapi/public/api/managment/get_warehouse",
             "create_warehouse": "http://localhost/tecnosmsapi/public/api/managment/create_warehouse",
             "update_warehouse": "http://localhost/tecnosmsapi/public/api/managment/update_warehouse",

             "get_stock_items_obj": "http://localhost/tecnosmsapi/public/api/managment/get_stock_items_obj",
             "get_stock_items": "http://localhost/tecnosmsapi/public/api/managment/get_stock_items",
             "get_stock_item": "http://localhost/tecnosmsapi/public/api/managment/get_stock_item",
             "create_stock_item": "http://localhost/tecnosmsapi/public/api/managment/create_stock_item",


             "get_category_stock_req": "http://localhost/tecnosmsapi/public/api/stockin/get_category_stock_req",
             "get_stock_items_stock_req": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_items_stock_req",
             "get_stock_items_fileds_stock_req": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_items_fileds_stock_req",
             "get_warehouse_stock_req": "http://localhost/tecnosmsapi/public/api/stockin/get_warehouse_stock_req",
             "create_stock_in_req": "http://localhost/tecnosmsapi/public/api/stockin/create_stock_in_req",
             "get_stock_in_req_items": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_req_items",
             "get_stock_in_req_item": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_req_item",
             "update_stock_in_req": "http://localhost/tecnosmsapi/public/api/stockin/update_stock_in_req",
             "delete_stock_in_req": "http://localhost/tecnosmsapi/public/api/stockin/delete_stock_in_req",
             "get_stock_in_app_items": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_app_items",
             "get_stock_in_app_item": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_app_item",
             "approval_stock_in": "http://localhost/tecnosmsapi/public/api/stockin/approval_stock_in",
             "get_stock_in_rec_items": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_rec_items",
             "get_stock_in_rec_item": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_rec_item",
             "receive_stock_in": "http://localhost/tecnosmsapi/public/api/stockin/receive_stock_in",
             "get_stock_in_daily_items": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_daily_items",
             "get_stock_in_daily_item": "http://localhost/tecnosmsapi/public/api/stockin/get_stock_in_daily_item",

             "get_category_stock_out_req": "http://localhost/tecnosmsapi/public/api/stockout/get_category_stock_out_req",
             "get_stock_items_stock_out_req": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_items_stock_out_req",
             "get_warehouse_stock_out_req": "http://localhost/tecnosmsapi/public/api/stockout/get_warehouse_stock_out_req",
             "get_stock_item_balance_by_stock_warehouse_stock_out": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_item_balance_by_stock_warehouse_stock_out",
             "get_stock_item_balance_by_fileds_stock_out": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_item_balance_by_fileds_stock_out",
             "create_stock_out_req": "http://localhost/tecnosmsapi/public/api/stockout/create_stock_out_req",
             "get_stock_out_req_items": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_req_items",
             "get_stock_out_req_item": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_req_item",
             "delete_stock_out_req": "http://localhost/tecnosmsapi/public/api/stockout/delete_stock_out_req",
             "get_stock_out_app_items": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_app_items",
             "get_stock_out_app_item": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_app_item",
             "approval_stock_out": "http://localhost/tecnosmsapi/public/api/stockout/approval_stock_out",
             "get_stock_out_rec_items": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_rec_items",
             "get_stock_out_rec_item": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_rec_item",
             "receive_stock_out": "http://localhost/tecnosmsapi/public/api/stockout/receive_stock_out",
             "get_stock_out_daily_items": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_daily_items",
             "get_stock_out_daily_item": "http://localhost/tecnosmsapi/public/api/stockout/get_stock_out_daily_item",


             "get_category_stock_wastage_in": "http://localhost/tecnosmsapi/public/api/stockwastage/get_category_stock_wastage_in",
             "get_stock_items_stock_wastage_in": "http://localhost/tecnosmsapi/public/api/stockwastage/get_stock_items_stock_wastage_in",
             "get_warehouse_stock_wastage_in": "http://localhost/tecnosmsapi/public/api/stockwastage/get_warehouse_stock_wastage_in",
             "get_stock_in_for_wastage": "http://localhost/tecnosmsapi/public/api/stockwastage/get_stock_in_for_wastage",
             "get_stock_in_for_wastage_single": "http://localhost/tecnosmsapi/public/api/stockwastage/get_stock_in_for_wastage_single",
             "create_update_wastage_in": "http://localhost/tecnosmsapi/public/api/stockwastage/create_update_wastage_in",

             "get_category_stock_wastage_out": "http://localhost/tecnosmsapi/public/api/stockwastage/get_category_stock_wastage_out",
             "get_stock_items_stock_wastage_out": "http://localhost/tecnosmsapi/public/api/stockwastage/get_stock_items_stock_wastage_out",
             "get_warehouse_stock_wastage_out": "http://localhost/tecnosmsapi/public/api/stockwastage/get_warehouse_stock_wastage_out",
             "get_stock_out_for_wastage": "http://localhost/tecnosmsapi/public/api/stockwastage/get_stock_out_for_wastage",
             "get_stock_out_for_wastage_single": "http://localhost/tecnosmsapi/public/api/stockwastage/get_stock_out_for_wastage_single",
             "create_update_wastage_out": "http://localhost/tecnosmsapi/public/api/stockwastage/create_update_wastage_out",

             "get_category_stock_transfer": "http://localhost/tecnosmsapi/public/api/stocktransfer/get_category_stock_transfer",
             "get_stock_items_stock_transfer": "http://localhost/tecnosmsapi/public/api/stocktransfer/get_stock_items_stock_transfer",
             "get_stock_items_for_report_2": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_stock_items_for_report_2",
             "get_warehouse_stock_transfer": "http://localhost/tecnosmsapi/public/api/stocktransfer/get_warehouse_stock_transfer",
             "get_stock_in_for_transfer": "http://localhost/tecnosmsapi/public/api/stocktransfer/get_stock_in_for_transfer",
             "get_stock_in_for_transfer_single": "http://localhost/tecnosmsapi/public/api/stocktransfer/get_stock_in_for_transfer_single",
             "transfer_stock_in": "http://localhost/tecnosmsapi/public/api/stocktransfer/transfer_stock_in",
             "get_stock_items_fileds_stock_transfer": "http://localhost/tecnosmsapi/public/api/stocktransfer/get_stock_items_fileds_stock_transfer",


             "get_category_for_report": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_category_for_report",
             "get_stock_items_for_report": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_stock_items_for_report",
             "get_warehouse_for_report_2": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_warehouse_for_report_2",
             "get_warehouse_for_report": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_warehouse_for_report",
             "get_r_inventory_summary": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_inventory_summary",
             "get_r_inventory_valuation": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_inventory_valuation",
             "get_r_fifo_cost_tracking": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_fifo_cost_tracking",
             "get_r_stock_summary": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_stock_summary",
             "get_r_stock_in": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_stock_in",
             "get_r_stock_out": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_stock_out",
             "get_users_for_report": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_users_for_report",
             "get_r_stock_in_wastage": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_stock_in_wastage",
             "get_r_stock_out_wastage": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_stock_out_wastage",
             "get_r_stock_transfer": "http://localhost/tecnosmsapi/public/api/inventoryreport/get_r_stock_transfer",


             "get_category_for_dashboard": "http://localhost/tecnosmsapi/public/api/dashboard/get_category_for_dashboard",
             "get_stock_items_for_dashboard": "http://localhost/tecnosmsapi/public/api/dashboard/get_stock_items_for_dashboard",
             "get_warehouse_for_dashboard": "http://localhost/tecnosmsapi/public/api/dashboard/get_warehouse_for_dashboard",
             "get_balance_for_dashboard": "http://localhost/tecnosmsapi/public/api/dashboard/get_balance_for_dashboard",
             "get_inventory_dashboard": "http://localhost/tecnosmsapi/public/api/dashboard/get_inventory_dashboard",
             "get_transaction_d_dashboard": "http://localhost/tecnosmsapi/public/api/dashboard/get_transaction_d_dashboard",
             "get_transaction_m_dashboard": "http://localhost/tecnosmsapi/public/api/dashboard/get_transaction_m_dashboard",


             "get_user_warehouse": "http://localhost/tecnosmsapi/public/api/role/get_user_warehouse",
             "get_user_warehouse_single": "http://localhost/tecnosmsapi/public/api/role/get_user_warehouse_single",
             "update_warehouse_role": "http://localhost/tecnosmsapi/public/api/role/update_warehouse_role",
             "get_user_approval": "http://localhost/tecnosmsapi/public/api/role/get_user_approval",
             "get_user_approval_single": "http://localhost/tecnosmsapi/public/api/role/get_user_approval_single",
             "update_approval_role": "http://localhost/tecnosmsapi/public/api/role/update_approval_role",

             "get_daily_in": "http://localhost/tecnosmsapi/public/api/daily/get_daily_in",
             "get_daily_out": "http://localhost/tecnosmsapi/public/api/daily/get_daily_out",
             "get_daily_transfer": "http://localhost/tecnosmsapi/public/api/daily/get_daily_transfer",


             "get_category_for_stock_summary": "http://localhost/tecnosmsapi/public/api/summary/get_category_for_stock_summary",
             "get_stock_items_for_stock_summary": "http://localhost/tecnosmsapi/public/api/summary/get_stock_items_for_stock_summary",
             "get_warehouse_for_stock_summary": "http://localhost/tecnosmsapi/public/api/summary/get_warehouse_for_stock_summary",
             "get_s_stock_in": "http://localhost/tecnosmsapi/public/api/summary/get_s_stock_in",
             "get_s_stock_out": "http://localhost/tecnosmsapi/public/api/summary/get_s_stock_out",
             "get_s_stock_in_wastage": "http://localhost/tecnosmsapi/public/api/summary/get_s_stock_in_wastage",
             "get_s_stock_out_wastage": "http://localhost/tecnosmsapi/public/api/summary/get_s_stock_out_wastage",
             "get_s_stock_transfer": "http://localhost/tecnosmsapi/public/api/summary/get_s_stock_transfer",
             "get_s_stock_balance": "http://localhost/tecnosmsapi/public/api/summary/get_s_stock_balance",


             "redirecttologin": "http://localhost:3000/login",
             "redirect": "http://localhost:3000",*/


            "urluser": "/user",
            "urlobjuser": "/objuser",
            "urluseraccess1": "/useraccess1",
            "urluseraccess2": "/useraccess2",
            "urlsiunit": "/siunit",
            "urlcategory": "/category",
            "urlstock_item": "/stock_item",
            "urlwarehouse": "/warehouse",
            "urlstock_in_req": "/stock_in_req",
            "urlstock_in_app": "/stock_in_app",
            "urlstock_in_daily": "/stock_in_daily",
            "urlstock_in_rec": "/stock_in_rec",
            "urlstock_out_req": "/stock_out_req",
            "urlstock_out_app": "/stock_out_app",
            "urlstock_out_rec": "/stock_out_rec",
            "urlstock_out_daily": "/stock_out_daily",
            "urlstock_wastage_in": "/stock_wastage_in",
            "urlstock_wastage_out": "/stock_wastage_out",
            "urlstock_transfer": "/stock_transfer",
            "urlr_inventory_summary": "/r_inventory_summary",
            "urlr_inventory_valuation": "/r_inventory_valuation",
            "urlr_fifo_cost_tracking": "/r_fifo_cost_tracking",
            "urlr_stock_summary": "/r_stock_summary",
            "urlinventory_dashboard": "/inventory_dashboard",
            "urltransaction_d_dashboard": "/transaction_d_dashboard",
            "urltransaction_m_dashboard": "/transaction_m_dashboard",
            "urlbalance_dashboard": "/balance_dashboard",
            "urlwarehouse_role": "/warehouse_role",
            "urlapproval_role": "/approval_role",
            "urlr_stock_in": "/r_stock_in",
            "urlr_stock_out": "/r_stock_out",
            "urlr_stock_in_wastage": "/r_stock_in_wastage",
            "urlr_stock_out_wastage": "/r_stock_out_wastage",
            "urlr_stock_transfer": "/r_stock_transfer",
            "urldaily_in": "/daily_in",
            "urldaily_out": "/daily_out",
            "urldaily_transfer": "/daily_transfer",
            "urls_stock_in": "/s_stock_in",
            "urls_stock_out": "/s_stock_out",
            "urls_stock_in_wastage": "/s_stock_in_wastage",
            "urls_stock_out_wastage": "/s_stock_out_wastage",
            "urls_stock_transfer": "/s_stock_transfer",
            "urls_stock_balance": "/s_stock_balance",
    }
export default myconfig;