import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Stock_out_app extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            objsload: false,
            filtered: [],

            id: -1,
            txtsearch: '',

            category: '',
            number_of_filed: '',
            siunit: '',
            catagory_name: '',
            stock_name: '',
            warehouse_name: '',
            req_qty: '',
            req_by: '',
            app_by: '',
            req_at: '',
            req_remark: '',
            app_qty: '',
            app_remark: '',
            approval: '',
            price: '',

            form_main_display: false,
            form_fileds_display: false,
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        this.setState({
            objs: [],
            objsload: false,
            filtered: [],

            id: -1,
            txtsearch: '',

            category: '',
            number_of_filed: '',
            siunit: '',
            catagory_name: '',
            stock_name: '',
            warehouse_name: '',
            req_qty: '',
            req_by: '',
            app_by: '',
            req_at: '',
            req_remark: '',
            app_qty: '',
            app_remark: '',
            approval: '-1',
            price: '',

            form_main_display: false,
            form_fileds_display: false,
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_stock_out_app_items;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered: res.data.datas,
                    objsload: true,

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    hideform = (event) => {
        event.preventDefault()
        this.setState({
            form_main_display: false,
            form_fileds_display: false,
        })
    }

    showform_show = (event, id = -1) => {
        event.preventDefault()
        this.setState({
            categories: [],
            category_selected: null,
            stock_items: [],
            stock_item_selected: null,
            name: '',
            req_qty: '',
            req_remark: '',
            category: '',
            number_of_filed: '',
            siunit: '',
            filed_1: '',
            filed_2: '',
            filed_3: '',
            filed_4: '',
            filed_5: '',
            filed_6: '',
            filed_7: '',
            filed_8: '',
            filed_9: '',
            filed_10: '',
            filed_11: '',
            filed_12: '',
            filed_13: '',
            filed_14: '',
            filed_15: '',
            form_main_display: false,
            form_fileds_display: false,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_out_app_item;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null)
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found for given id",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    else
                        this.setState({
                            req_qty: res.data.data.req_qty,
                            app_qty: res.data.data.app_qty,
                            req_remark: res.data.data.req_remark,
                            show_category: res.data.data.show_category,
                            show_stock_item: res.data.data.show_stock_item,
                            show_ware_house: res.data.data.show_ware_house,
                            stock_balance: res.data.balance,
                            category: res.data.data.show_category,
                            approval: res.data.data.approval,
                            number_of_filed: res.data.sitem.number_of_filed,
                            obj: res.data.sitem,
                            siunit: res.data.data.siunit,
                            req_by: res.data.data.req_by,
                            app_by: res.data.data.app_by,
                            filed_1: res.data.data.stockin.filed_1,
                            filed_2: res.data.data.stockin.filed_2,
                            filed_3: res.data.data.stockin.filed_3,
                            filed_4:res.data.data.stockin.filed_4,
                            filed_5: res.data.data.stockin.filed_5,
                            filed_6: res.data.data.stockin.filed_6,
                            filed_7: res.data.data.stockin.filed_7,
                            filed_8: res.data.data.stockin.filed_8,
                            filed_9: res.data.data.stockin.filed_9,
                            filed_10: res.data.data.stockin.filed_10,
                            filed_11: res.data.data.stockin.filed_11,
                            filed_12: res.data.data.stockin.filed_12,
                            filed_13: res.data.data.stockin.filed_13,
                            filed_14: res.data.data.stockin.filed_14,
                            filed_15: res.data.data.stockin.filed_15,
                            type: 'show',
                            form_main_display: true,
                            form_fileds_display: true,
                        })
                }
            }).catch((e) => {
                console.log(e.message);
            });

        })
    }
    showform_approval = (event, id = -1) => {
        event.preventDefault()
        this.setState({
            categories: [],
            category_selected: null,
            stock_items: [],
            stock_item_selected: null,
            name: '',
            req_qty: '',
            req_remark: '',
            category: '',
            number_of_filed: '',
            siunit: '',
            filed_1: '',
            filed_2: '',
            filed_3: '',
            filed_4: '',
            filed_5: '',
            filed_6: '',
            filed_7: '',
            filed_8: '',
            filed_9: '',
            filed_10: '',
            filed_11: '',
            filed_12: '',
            filed_13: '',
            filed_14: '',
            filed_15: '',
            form_main_display: false,
            form_fileds_display: false,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_out_app_item;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null)
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found for given id",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    else
                        this.setState({
                            id: id,
                            req_qty: res.data.data.req_qty,
                            app_qty: res.data.data.app_qty,
                            req_remark: res.data.data.req_remark,
                            show_category: res.data.data.show_category,
                            show_stock_item: res.data.data.show_stock_item,
                            show_ware_house: res.data.data.show_ware_house,
                            category: res.data.data.show_category,
                            number_of_filed: res.data.sitem.number_of_filed,
                            obj: res.data.sitem,
                            warehouse_selected: res.data.warehouse_edit,
                            stock_balance: res.data.balance,
                            approval: res.data.data.approval,
                            siunit: res.data.data.siunit,
                            req_by: res.data.data.req_by,
                            app_by: res.data.data.app_by,
                            filed_1: res.data.data.stockin.filed_1,
                            filed_2: res.data.data.stockin.filed_2,
                            filed_3: res.data.data.stockin.filed_3,
                            filed_4:res.data.data.stockin.filed_4,
                            filed_5: res.data.data.stockin.filed_5,
                            filed_6: res.data.data.stockin.filed_6,
                            filed_7: res.data.data.stockin.filed_7,
                            filed_8: res.data.data.stockin.filed_8,
                            filed_9: res.data.data.stockin.filed_9,
                            filed_10: res.data.data.stockin.filed_10,
                            filed_11: res.data.data.stockin.filed_11,
                            filed_12: res.data.data.stockin.filed_12,
                            filed_13: res.data.data.stockin.filed_13,
                            filed_14: res.data.data.stockin.filed_14,
                            filed_15: res.data.data.stockin.filed_15,
                            type: 'edit',
                            form_main_display: true,
                            form_fileds_display: true,
                        })
                    console.log(res.data.warehouse_edit)
                }
            }).catch((e) => {
                console.log(e.message);
            });

        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    form_stock_req = () => {
        let retdata = null;

        if (this.state.form_main_display) {
            let isDisabled = false
            let header = 'Stock OUT Approval';
            let button_sub = <form action="#" method="post">
                <button onClick={this.btnapproval} type="button" className="btn btn-block btn-primary">Stock OUT APPROVE
                </button>
            </form>
            if (this.state.type == "show") {
                header = 'Show Stock OUT Approval';
                isDisabled = true
                button_sub = null;
            }
            retdata = <div className="col-12">
                <div className="card">
                    <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                        <h3 className="card-title">{header}</h3>
                        <div className="card-tools">
                            <button type="button" onClick={this.hideform} className="btn btn-tool">
                                <i className="fas fa-times"/>
                            </button>
                        </div>
                    </div>
                    <form>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label>Category </label>
                                        <input disabled={true} className="form-control" type="text" name="show_category"
                                               onChange={this.inputmgt} value={this.state.show_category}/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label>Stock Item</label>
                                        <input disabled={true} className="form-control" type="text"
                                               name="show_stock_item"
                                               onChange={this.inputmgt} value={this.state.show_stock_item}/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Stock Item</label>
                                            <input disabled={true} className="form-control" type="text"
                                                   name="show_ware_house"
                                                   onChange={this.inputmgt} value={this.state.show_ware_house}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Unit of Measurement</label>
                                            <input disabled={true} className="form-control" type="text" name="siunit"
                                                   onChange={this.inputmgt} value={this.state.siunit}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label># of Fields</label>
                                            <input disabled={true} className="form-control" type="text"
                                                   name="number_of_filed" onChange={this.inputmgt}
                                                   value={this.state.number_of_filed}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Requested By</label>
                                            <input disabled={true} className="form-control" type="text" name="req_by"
                                                   onChange={this.inputmgt} value={this.state.req_by}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Approved By</label>
                                            <input disabled={true} className="form-control" type="text"
                                                   name="app_by" onChange={this.inputmgt}
                                                   value={this.state.app_by}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label>Request Remark</label>
                                        <textarea disabled={true} onChange={this.inputmgt} rows={5}
                                                  className="form-control"
                                                  name="req_remark" value={this.state.req_remark}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label>Approved Remark</label>
                                        <textarea disabled={isDisabled} onChange={this.inputmgt} rows={5}
                                                  className="form-control"
                                                  name="app_remark" value={this.state.app_remark}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Approval</label>
                                            <select disabled={isDisabled} className="form-control" name="approval" onChange={this.inputmgt}
                                                    value={this.state.approval}>
                                                <option value="-1">-- Select Status --</option>
                                                <option value="reset">Reset</option>
                                                <option value="approve">Approve</option>
                                                <option value="reject">Reject</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this.form_stock_req_fields()}
                            </div>
                        </div>
                    </form>
                    {button_sub}
                </div>
            </div>
        }
        return (<>{retdata}</>)
    }
    form_stock_req_fields = () => {
        let retdata = null;
        if (this.state.form_fileds_display) {
            let isDisabled = false
            if (this.state.type == "show") {
                isDisabled = true;
            }
            let arr = [];
            for (let i = 0; i < this.state.number_of_filed; i++) {
                arr.push(i + 1)
            }
            let d = arr.map(v => {
                return (
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>{this.state.obj["filed_name_" + v]}</label>
                            <div className="input-group mb-3">
                                <input disabled={true} type="text" value={this.state["filed_" + v]}
                                       className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">{this.state.obj["filed_siunit_" + v]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
            retdata = <>
                {d}
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <div className="form-group">
                            <label>Stock Balance</label>
                            <input disabled={true} className="form-control" type="text" name="stock_balance"
                                   onChange={this.inputmgt} value={this.state.stock_balance}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <div className="form-group">
                            <label>Request Quantity</label>
                            <input disabled={true} className="form-control" type="text" name="req_qty"
                                   onChange={this.inputmgt} value={this.state.req_qty}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <div className="form-group">
                            <label>Approved Quantity</label>
                            <input disabled={isDisabled} className="form-control" type="text" name="app_qty"
                                   onChange={this.inputmgt} value={this.state.app_qty}/>
                        </div>
                    </div>
                </div>

            </>;
        }
        return (<>{retdata}</>)
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    btnapproval = (event) => {
        event.preventDefault()
        let baseurl = myconfig.approval_stock_out;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: this.state.id,
            app_qty: this.state.app_qty,
            app_remark: this.state.app_remark,
            approval: this.state.approval,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    tabledata = () => {
        let retdata = null;
        if (this.state.objsload) {
            let count = 0;
            let app = null;
            retdata = this.state.filtered.map(value => {
                count = count + 1;
                if (this.state.objs.usermatrix.update == true)
                    app = <td className="col-md-1"><a href=""
                                                      onClick={event => this.showform_approval(event, value.id)}>Approve</a>
                    </td>
                return (<tr>
                    <td className="col-md-1">{count}</td>
                    <td className="col-md-1"><a href="" onClick={event => this.showform_show(event, value.id)}>Show</a>
                    </td>
                    {app}
                    <td className="col-md-1">{value.cateory.name}</td>
                    <td className="col-md-1">{value.stockitem.name}</td>
                    <td className="col-md-1">{value.warehouse.name}</td>
                    <td className="col-md-1">{value.warehouse.location}</td>
                    <td className="col-md-1">{value.app_by}</td>
                    <td className="col-md-1">{value.app_at}</td>
                    <td className="col-md-1">{value.app_qty}</td>
                    <td className="col-md-1">{value.siunit.name}</td>
                    <td className="col-md-1">{value.status}</td>

                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    render() {
        let app = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.update == true)
                app = <th className="col-md-1">Approve</th>
        }
        return (
            <>
                <Nav type="stock_out_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock OUT Approval</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Approval</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {this.form_stock_req()}
                                    <div className="card">
                                    {/*    <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed ">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-1">#</th>
                                                    <th className="col-md-1">Show</th>
                                                    {app}
                                                    <th className="col-md-1">Category</th>
                                                    <th className="col-md-1">Item Name</th>
                                                    <th className="col-md-1">Warehouse</th>
                                                    <th className="col-md-1">Location</th>
                                                    <th className="col-md-1">By</th>
                                                    <th className="col-md-1">At</th>
                                                    <th className="col-md-1">Quantity</th>
                                                    <th className="col-md-1">Unit</th>
                                                    <th className="col-md-1">Status</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}
export default Stock_out_app;
