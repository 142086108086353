import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Stock_transfer extends Component {

    constructor() {
        super();
        this.state = {
            datas:[],
            rowshead:[],


            dets:[],


            categories: [],
            category_selected: null,

            stock_items: [],
            stock_item_selected: null,

            warehouses: [],
            main_warehouses: [],
            warehouse_selected: null,

            warehouses2: [],
            warehouse_selected2: null,

            fileds: [],
            form_fileds_display: false,
            filed_1: null,
            filed_2: null,
            filed_3: null,
            filed_4: null,
            filed_5: null,
            filed_6: null,
            filed_7: null,
            filed_8: null,
            filed_9: null,
            filed_10: null,
            filed_11: null,
            filed_12: null,
            filed_13: null,
            filed_14: null,
            filed_15: null,
            stock_balance: '',
            req_qty: '',
            alltime: false,
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            isdate: false,

            category: '',
            itemname: '',
            warehouse: '',
            siunit: '',
            balance: '',
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    fetcheddata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_warehouse_stock_transfer;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    main_warehouses: res.data.datas,
                    warehouses2: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
        /*        baseurl = myconfig.get_stock_out_req_items;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("tecnosms_sid"),
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("tecnosms_sid", null);
                        window.localStorage.setItem("tecnosms_username", null);
                        window.localStorage.setItem("tecnosms_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            objs: res.data,
                            filtered: res.data.datas,
                            objsload: true,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });*/
        baseurl = myconfig.get_category_stock_transfer;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    categories: res.data.categories,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    reset = () => {
        this.setState({
            form_fileds_display: false,
            warehouse_selected2: null,
            id: '-1',
            category: '',
            itemname: '',
            warehouse: '',
            siunit: '',
            balance:'',
            dets:[],
        })
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    handelchange_category = (category_selected) => {
        this.reset()
        this.setState({
            warehouses: [],
            warehouse_selected: null,
            stock_items: [],
            stock_item_selected: null,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_items_stock_transfer;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: category_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        stock_items: res.data.datas,
                        category_selected: category_selected,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    handelchange_stock_item = (stock_item_selected) => {
        this.reset()
        this.setState({
            warehouses: [],
            warehouse_selected: null,
        }, () => {
            this.setState({
                warehouses: this.state.main_warehouses,
                stock_item_selected: stock_item_selected,
            })
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_items_fileds_stock_transfer;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: stock_item_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
                else {
                    if (res.data.data != null) {
                        this.setState({
                            category: res.data.data.category,
                            number_of_filed: res.data.data.number_of_filed,
                            siunit: res.data.data.siunit,
                        })
                    } else {
                        this.setState({
                            category: "",
                            number_of_filed: "",
                            siunit:  "",
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    handelchange_warehouse = (warehouse_selected) => {
        this.setState({
            warehouse_selected: warehouse_selected,
            form_fileds_display: false,
            dets:[],
        })
    }
    hideform = (event) => {
        event.preventDefault()
        this.setState({
            form_fileds_display: false,
        })
    }
    showform = (event,id) => {
        event.preventDefault()
        this.reset()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_stock_in_for_transfer_single;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    form_fileds_display: true,
                    warehouse_selected2: null,
                    id: id,
                    category: res.data.category,
                    itemname: res.data.itemname,
                    warehouse: res.data.warehouse,
                    siunit: res.data.siunit,
                    balance: res.data.balance,
                    dets:res.data.dets,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputchk = (event) => {

        const target = event.target;
        const value = target.checked;
        const name = target.name;
        this.setState({
            [name]: value,
            isdate: value,
        })
    }
    form_stock_get = () => {
        let isDisabled = false
        let sec_1 = <>
            <div className="col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Category </label>
                    <Select
                        isDisabled={isDisabled}
                        value={this.state.category_selected}
                        onChange={this.handelchange_category}
                        options={this.state.categories}
                    />
                </div>
            </div>
            <div className="col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Stock Item</label>
                    <Select
                        isDisabled={isDisabled}
                        value={this.state.stock_item_selected}
                        onChange={this.handelchange_stock_item}
                        options={this.state.stock_items}
                    />
                </div>
            </div>
            <div className="col-md-4 col-sm-6">
                <div className="form-group">
                    <label>Warehouse</label>
                    <Select
                        isDisabled={isDisabled}
                        value={this.state.warehouse_selected}
                        onChange={this.handelchange_warehouse}
                        options={this.state.warehouses}
                    />
                </div>
            </div>
        </>
        let header = 'Stock Transfer Management';
        let retdata  = <div className="col-12">
            <div className="card">
                <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">{header}</h3>
                    <div className="card-tools">
                    </div>
                </div>
                <form>
                    <div className="card-body">
                        <div className="row">
                            {sec_1}
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <input disabled={true} className="form-control" type="text" name="category"
                                               onChange={this.inputmgt} value={this.state.category}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Unit of Measurement</label>
                                        <input disabled={true} className="form-control" type="text" name="siunit"
                                               onChange={this.inputmgt} value={this.state.siunit}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label># of Fields</label>
                                        <input disabled={true} className="form-control" type="text"
                                               name="number_of_filed" onChange={this.inputmgt}
                                               value={this.state.number_of_filed}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input disabled={this.state.isdate} className="form-control" type="date"
                                               name="st" onChange={this.inputmgt} value={this.state.st}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input disabled={this.state.isdate} className="form-control" type="date"
                                               name="ed" onChange={this.inputmgt} value={this.state.ed}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>All Time</label>
                                        <input className="form-control justify-content-start d-flex" type="checkbox"
                                               name="alltime" onChange={this.inputchk}
                                               checked={this.state.alltime}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <button onClick={this.btnget_data} type="submit" className="btn btn-warning">Get Data's
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                            </div>
                            <div className="col-md-4 col-sm-6">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        return (<>{retdata}</>)
    }
    form_stock_wastage = () => {
        let retdata = null;
        if (this.state.form_fileds_display) {
            retdata = <>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-fuchsia ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Stock Transfer Management</h3>
                            <div className="card-tools">
                                <button type="button" onClick={this.hideform} className="btn btn-tool">
                                    <i className="fas fa-times"/>
                                </button>
                            </div>
                        </div>
                        <form>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Category Name </label>
                                                <input disabled={true} className="form-control" type="text" name="category"
                                                       onChange={this.inputmgt} value={this.state.category}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Item Name </label>
                                                <input disabled={true} className="form-control" type="text" name="itemname"
                                                       onChange={this.inputmgt} value={this.state.itemname}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Warehouse</label>
                                                <input disabled={true} className="form-control" type="text" name="warehouse"
                                                       onChange={this.inputmgt} value={this.state.warehouse}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>SI Unit</label>
                                                <input disabled={true} className="form-control" type="text" name="siunit"
                                                       onChange={this.inputmgt} value={this.state.siunit}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Current Quantity</label>
                                                <input disabled={true} className="form-control" type="text" name="balance"
                                                       onChange={this.inputmgt} value={this.state.balance}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <label>Transfer Warehouse</label>
                                            <Select
                                                value={this.state.warehouse_selected2}
                                                onChange={this.handelchange_warehous_2}
                                                options={this.state.warehouses2}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Transfer Quantity</label>
                                                <input className="form-control" type="text" name="transfer_qty"
                                                       onChange={this.inputmgt} value={this.state.transfer_qty}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <label>Transfer Remark</label>
                                            <textarea onChange={this.inputmgt} rows={5}
                                                      className="form-control"
                                                      name="transfer_remark" value={this.state.transfer_remark}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button onClick={this.btnsave_transfer} type="button" className="btn btn-block btn-primary">Stock Transfer
                        </button>
                    </div>
                </div>

            </>;
        }
        return (<>{retdata}</>)
    }
    form_stock_information = () => {
        let retdata = null;
        if (this.state.form_fileds_display) {
            if(this.state.dets.length>0){
                retdata = <>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Information</h3>
                                <div className="card-tools">
                                    <button type="button" onClick={this.hideform} className="btn btn-tool">
                                        <i className="fas fa-times"/>
                                    </button>
                                </div>
                            </div>
                            <form>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Category </label>
                                                    <input value={this.state.dets[0].category_name} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Warehouse</label>
                                                    <input value={this.state.dets[0].warehouse} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Spec</label>
                                                <textarea  value={this.state.dets[0].spec}
                                                           rows={5}
                                                           className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Expire Date</label>
                                                    <input  value={this.state.dets[0].expire_date} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <input  value={this.state.dets[0].price} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Rec At</label>
                                                    <input  value={this.state.dets[0].rec_at} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Req Qty</label>
                                                    <input  value={this.state.dets[0].req_qty} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>App Qty</label>
                                                    <input  value={this.state.dets[0].app_qty} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Current Qty</label>
                                                    <input  value={this.state.balance} disabled={true} className="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>;
            }

        }
        return (<>{retdata}</>)
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    btnget_data = (event) => {
        event.preventDefault()
        this.fetch2();
    }
    fetch2 = () => {
        this.setState({
            form_fileds_display: false,
            dets:[],
        })
        if (this.state.stock_item_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Stock Item is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.warehouse_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Warehouse is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_in_for_transfer;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                stock_item_id: this.state.stock_item_selected.value,
                warehouse_id: this.state.warehouse_selected.value,
                st: this.state.st,
                ed: this.state.ed,
                alltime: this.state.alltime,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        datas: res.data.datas,
                        rowshead: res.data.rowshead,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnsave_transfer = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.transfer_stock_in;
        if (this.state.warehouse_selected2 == null) {
            Swal.fire({
                title: 'Error!',
                text: "Warehouse is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: this.state.id,
                to: this.state.warehouse_selected2.value,
                transfer_qty: this.state.transfer_qty,
                transfer_remark: this.state.transfer_remark,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(value => {
                        this.setState({
                            form_fileds_display: false,
                            wastage_qty: '',
                            wastage_remark: '',
                        })
                        this.fetch2()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    tabledata = () => {
        let count=0;
        let tabledata=this.state.datas.map(v=>{
            count=count+1;
            let rows=v.rows.map(v2=>{
                return <td>{v2.filed_value} {v2.filed_siunit}</td>
            })
            return (
                <tr>
                    <td>{count}</td>
                    <td><a href="" onClick={event => this.showform(event,v.id)}>Transfer</a></td>
                    <td>{v.category}</td>
                    <td>{v.itemname}</td>
                    <td>{v.warehouse}</td>
                    <td>{v.app_qty}</td>
                    <td>{v.app_qty_after}</td>
                    <td>{v.siunit}</td>
                    {rows}
                </tr>
            )
        })
        let rowhead=this.state.rowshead.map(v=>{
            return<th className="col-md-1">{v.filed_name}</th>
        });
        let table =   <div className="card-body table-responsive p-0" style={{height: '500px'}}>
            <table className="table table-head-fixed text-nowrap">
                <thead>
                <tr>
                    <th className="col-md-1">#</th>
                    <th className="col-md-1">Transfer</th>
                    <th className="col-md-1">Category</th>
                    <th className="col-md-1">Item Name</th>
                    <th className="col-md-1">Warehouse</th>
                    <th className="col-md-1">Start QTY</th>
                    <th className="col-md-1">Current QTY</th>
                    <th className="col-md-1">Unit</th>
                    {rowhead}
                </tr>
                </thead>
                <tbody>
                {tabledata}
                </tbody>
            </table>
        </div>;
        return (<>{table}</>)
    }
    render() {
        let del = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.update == true)
                del = <th className="col-md-1">Delete</th>
        }
        return (
            <>
                <Nav type="stock_transfer"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock Transfer</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Transfer</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {this.form_stock_get()}
                                    {this.form_stock_information()}
                                    {this.form_stock_wastage()}
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.tabledata()}
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Stock_transfer;
