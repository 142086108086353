import {Component} from 'react';
import axios from "axios"
import myconfig from "../myconfig";
import Swal from "sweetalert2";
import {Modal} from "react-bootstrap";
import style2 from "../style2.css"

let source = axios.CancelToken.source();

class Nav extends Component {
    constructor() {
        super();

        this.state = {
            matrix: [],
            martixload: false,
            username: '',

            usermgt: 'nav-item',
            usermgt1: 'nav-link',
            user: 'nav-link',

            objuser: 'nav-link',
            useraccess1: 'nav-link',
            usertime: 'nav-link',

            mgtmgt: 'nav-item',
            mgtmgt1: 'nav-link',
            siunit: 'nav-link',
            category: 'nav-link',
            stock_item: 'nav-link',
            warehouse: 'nav-link',

            stock_inmgt: 'nav-item',
            stock_inmgt1: 'nav-link',
            stock_in_req: 'nav-link',
            stock_in_app: 'nav-link',
            stock_in_daily: 'nav-link',
            stock_in_rec: 'nav-link',

            stock_outmgt: 'nav-item',
            stock_outmgt1: 'nav-link',
            stock_out_req: 'nav-link',
            stock_out_app: 'nav-link',
            stock_out_daily: 'nav-link',
            stock_out_rec: 'nav-link',


            stock_wastagemgt: 'nav-item',
            stock_wastagemgt1: 'nav-link',
            stock_wastage_in: 'nav-link',
            stock_wastage_out: 'nav-link',

            r_inventorymgt: 'nav-item',
            r_inventorymgt1: 'nav-link',
            r_inventory_summary: 'nav-link',
            r_inventory_valuation: 'nav-link',
            r_fifo_cost_tracking: 'nav-link',
            r_stock_summary: 'nav-link',
            r_stock_in: 'nav-link',
            r_stock_out: 'nav-link',
            r_stock_in_wastage: 'nav-link',
            r_stock_out_wastage: 'nav-link',
            r_stock_transfer: 'nav-link',

            dashboardmgt: 'nav-item',
            dashboardmgt1: 'nav-link',
            inventory_dashboard: 'nav-link',
            transaction_d_dashboard: 'nav-link',
            transaction_m_dashboard: 'nav-link',
            balance_dashboard: 'nav-link',

            rolemgt: 'nav-item',
            rolemgt1: 'nav-link',
            warehouse_role: 'nav-link',
            approval_role: 'nav-link',

            dailymgt: 'nav-item',
            dailymgt1: 'nav-link',
            daily_in: 'nav-link',
            daily_out: 'nav-link',
            daily_transfer: 'nav-link',


            changepwd: 'nav-link',

            s_stockmgt: 'nav-item',
            s_stockmgt1: 'nav-link',
            s_stock_in: 'nav-link',
            s_stock_out: 'nav-link',
            s_stock_in_wastage: 'nav-link',
            s_stock_out_wastage: 'nav-link',
            s_stock_transfer: 'nav-link',
            s_stock_balance: 'nav-link',
        }
    }

    fetcheduseraccess = () => {
        let baseurl = myconfig.getuasession;
        let sid = window.localStorage.getItem("tecnosms_sid")
        axios.post(baseurl, {
            sid: sid,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(myconfig.redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data.datas)
                let un= res.data.username==null?"Unknown User":res.data.username
                this.setState({
                    matrix: res.data.datas,
                    username:un,
                    martixload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentDidMount() {
        this.fetcheduseraccess();
        if (this.props.type == "user") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    user: 'nav-link active',
                }
            );
        } else if (this.props.type == "objuser") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    objuser: 'nav-link active',
                }
            );
        } else if (this.props.type == "useraccess1") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    useraccess1: 'nav-link active',
                }
            );
        } else if (this.props.type == "siunit") {
            this.setState(
                {
                    mgtmgt: 'nav-item menu-open active',
                    mgtmgt1: 'nav-link active',
                    siunit: 'nav-link active',
                }
            );
        } else if (this.props.type == "category") {
            this.setState(
                {
                    mgtmgt: 'nav-item menu-open active',
                    mgtmgt1: 'nav-link active',
                    category: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_item") {
            this.setState(
                {
                    mgtmgt: 'nav-item menu-open active',
                    mgtmgt1: 'nav-link active',
                    stock_item: 'nav-link active',
                }
            );
        } else if (this.props.type == "warehouse") {
            this.setState(
                {
                    mgtmgt: 'nav-item menu-open active',
                    mgtmgt1: 'nav-link active',
                    warehouse: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_in_req") {
            this.setState(
                {
                    stock_inmgt: 'nav-item menu-open active',
                    stock_inmgt1: 'nav-link active',
                    stock_in_req: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_in_app") {
            this.setState(
                {
                    stock_inmgt: 'nav-item menu-open active',
                    stock_inmgt1: 'nav-link active',
                    stock_in_app: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_in_rec") {
            this.setState(
                {
                    stock_inmgt: 'nav-item menu-open active',
                    stock_inmgt1: 'nav-link active',
                    stock_in_rec: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_in_daily") {
            this.setState(
                {
                    stock_inmgt: 'nav-item menu-open active',
                    stock_inmgt1: 'nav-link active',
                    stock_in_daily: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_req") {
            this.setState(
                {
                    stock_outmgt: 'nav-item menu-open active',
                    stock_outmgt1: 'nav-link active',
                    stock_out_req: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_app") {
            this.setState(
                {
                    stock_outmgt: 'nav-item menu-open active',
                    stock_outmgt1: 'nav-link active',
                    stock_out_app: 'nav-link active',
                }
            );
        } else if (this.props.type == "stock_out_rec") {
            this.setState(
                {
                    stock_outmgt: 'nav-item menu-open active',
                    stock_outmgt1: 'nav-link active',
                    stock_out_rec: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "stock_out_daily") {
            this.setState(
                {
                    stock_outmgt: 'nav-item menu-open active',
                    stock_outmgt1: 'nav-link active',
                    stock_out_daily: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "stock_wastage_in") {
            this.setState(
                {
                    stock_wastagemgt: 'nav-item menu-open active',
                    stock_wastagemgt1: 'nav-link active',
                    stock_wastage_in: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "stock_wastage_out") {
            this.setState(
                {
                    stock_wastagemgt: 'nav-item menu-open active',
                    stock_wastagemgt1: 'nav-link active',
                    stock_wastage_out: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_inventory_summary") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_inventory_summary: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_inventory_valuation") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_inventory_valuation: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_fifo_cost_tracking") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_fifo_cost_tracking: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_stock_summary") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_stock_summary: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_stock_in") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_stock_in: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_stock_out") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_stock_out: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_stock_in_wastage") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_stock_in_wastage: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_stock_out_wastage") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_stock_out_wastage: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_stock_transfer") {
            this.setState(
                {
                    r_inventorymgt: 'nav-item menu-open active',
                    r_inventorymgt1: 'nav-link active',
                    r_stock_transfer: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "inventory_dashboard") {
            this.setState(
                {
                    dashboardmgt: 'nav-item menu-open active',
                    dashboardmgt1: 'nav-link active',
                    inventory_dashboard: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "transaction_d_dashboard") {
            this.setState(
                {
                    dashboardmgt: 'nav-item menu-open active',
                    dashboardmgt1: 'nav-link active',
                    transaction_d_dashboard: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "transaction_m_dashboard") {
            this.setState(
                {
                    dashboardmgt: 'nav-item menu-open active',
                    dashboardmgt1: 'nav-link active',
                    transaction_m_dashboard: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "balance_dashboard") {
            this.setState(
                {
                    dashboardmgt: 'nav-item menu-open active',
                    dashboardmgt1: 'nav-link active',
                    balance_dashboard: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "warehouse_role") {
            this.setState(
                {
                    rolemgt: 'nav-item menu-open active',
                    rolemgt1: 'nav-link active',
                    warehouse_role: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "approval_role") {
            this.setState(
                {
                    rolemgt: 'nav-item menu-open active',
                    rolemgt1: 'nav-link active',
                    approval_role: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "daily_in") {
            this.setState(
                {
                    dailymgt: 'nav-item menu-open active',
                    dailymgt1: 'nav-link active',
                    daily_in: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "daily_out") {
            this.setState(
                {
                    dailymgt: 'nav-item menu-open active',
                    dailymgt1: 'nav-link active',
                    daily_out: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "daily_transfer") {
            this.setState(
                {
                    dailymgt: 'nav-item menu-open active',
                    dailymgt1: 'nav-link active',
                    daily_transfer: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "s_stock_in") {
            this.setState(
                {
                    s_stockmgt: 'nav-item menu-open active',
                    s_stockmgt1: 'nav-link active',
                    s_stock_in: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "s_stock_out") {
            this.setState(
                {
                    s_stockmgt: 'nav-item menu-open active',
                    s_stockmgt1: 'nav-link active',
                    s_stock_out: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "s_stock_in_wastage") {
            this.setState(
                {
                    s_stockmgt: 'nav-item menu-open active',
                    s_stockmgt1: 'nav-link active',
                    s_stock_in_wastage: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "s_stock_out_wastage") {
            this.setState(
                {
                    s_stockmgt: 'nav-item menu-open active',
                    s_stockmgt1: 'nav-link active',
                    s_stock_out_wastage: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "s_stock_transfer") {
            this.setState(
                {
                    s_stockmgt: 'nav-item menu-open active',
                    s_stockmgt1: 'nav-link active',
                    s_stock_transfer: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "s_stock_balance") {
            this.setState(
                {
                    s_stockmgt: 'nav-item menu-open active',
                    s_stockmgt1: 'nav-link active',
                    s_stock_balance: 'nav-link active',
                }
            );
        }
    }
    logout = (event) => {
        event.preventDefault();
        let baseurl = myconfig.logout
        axios.post(baseurl, {
            'sid': window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
        }).catch((e) => {
            console.log(e.message);
        });
        window.localStorage.setItem("tecnosms_sid", null);
        window.localStorage.setItem("tecnosms_username", null);
        window.localStorage.setItem("tecnosms_name", null);
        if (window.localStorage.getItem("tecnosms_sid_pin") == "true")
            var win = window.open(myconfig.redirecttoauth_2, "_self");
        else
            var win = window.open(myconfig.redirecttologin, "_self");
    }
    displaymenus = () => {
        if (this.state.martixload) {
            let user = "";
            let objuser = "";
            let useraccess1 = "";
            let usermenu = "";
            let usermgt = this.state.matrix.filter(value => {
                if (value.object_name == "user" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    user =
                        <a href={myconfig.urluser} className={this.state.user}><i className="far fa-circle nav-icon"/>
                            <p>User</p></a>
                    return value;
                }
                if (value.object_name == "objuser" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    objuser =
                        <a href={myconfig.urlobjuser} className={this.state.objuser}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Object</p></a>
                    return value;
                }
                if (value.object_name == "useraccess1" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    useraccess1 =
                        <a href={myconfig.urluseraccess1} className={this.state.useraccess1}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Access</p></a>
                    return value;
                }
            })
            if (usermgt.length > 0) {
                usermenu = <li name="usermgt" onClick={(event => {
                    this.menuclick(event, this.state.usermgt)
                })} className={this.state.usermgt}>
                    <a name="usermgt" onClick={(event => {
                        this.menuclick(event, this.state.usermgt)
                    })} href="#" className={this.state.usermgt1}>
                        <i className="nav-icon fas fa-user"/>
                        <p>
                            User Management
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {user}
                            {objuser}
                            {useraccess1}
                        </li>
                    </ul>
                </li>
            }

            let warehouse_role = "";
            let approval_role = "";
            let rolemenu = "";
            let rolemgt = this.state.matrix.filter(value => {
                if (value.object_name == "warehouse_role" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    warehouse_role =
                        <a href={myconfig.urlwarehouse_role} className={this.state.warehouse_role}><i className="far fa-circle nav-icon"/>
                            <p>Warehouse</p></a>
                    return value;
                }
                if (value.object_name == "approval_role" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    approval_role =
                        <a href={myconfig.urlapproval_role} className={this.state.approval_role}><i className="far fa-circle nav-icon"/>
                            <p>Access Privilege</p></a>
                    return value;
                }
            })


            if (rolemgt.length > 0) {
                rolemenu = <li name="rolemgt" onClick={(event => {
                    this.menuclick(event, this.state.rolemgt)
                })} className={this.state.rolemgt}>
                    <a name="rolemgt" onClick={(event => {
                        this.menuclick(event, this.state.rolemgt)
                    })} href="#" className={this.state.rolemgt1}>
                        <i className="nav-icon fas fa-broadcast-tower"/>
                        <p>
                            Role Management
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {warehouse_role}
                            {approval_role}
                        </li>
                    </ul>
                </li>
            }


            let singlemenu_changepwd = this.state.matrix.filter(value => {
                if (value.object_name == "changepwd" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_changepwd.length > 0)
                singlemenu_changepwd = <li className="nav-item">
                    <a href="" onClick={this.showcu} className={this.state.changepwd}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Change Password</p>
                    </a>
                </li>
            else
                singlemenu_changepwd = null;


            let siunit = "";
            let category = "";
            let stock_item = "";
            let warehouse = "";
            let mgtmenu = "";
            let mgtmgt = this.state.matrix.filter(value => {
                if (value.object_name == "siunit" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    siunit =
                        <a href={myconfig.urlsiunit} className={this.state.siunit}><i
                            className="far fa-circle nav-icon"/>
                            <p>Unit Of Measurement</p></a>
                    return value;
                }
                if (value.object_name == "category" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    category =
                        <a href={myconfig.urlcategory} className={this.state.category}><i
                            className="far fa-circle nav-icon"/>
                            <p>Category</p></a>
                    return value;
                }
                if (value.object_name == "stock_item" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_item =
                        <a href={myconfig.urlstock_item} className={this.state.stock_item}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Item</p></a>
                    return value;
                }
                if (value.object_name == "warehouse" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    warehouse =
                        <a href={myconfig.urlwarehouse} className={this.state.warehouse}><i
                            className="far fa-circle nav-icon"/>
                            <p>Warehouse</p></a>
                    return value;
                }
            })
            if (mgtmgt.length > 0) {
                mgtmenu = <li name="mgtmgt" onClick={(event => {
                    this.menuclick(event, this.state.mgtmgt)
                })} className={this.state.mgtmgt}>
                    <a name="mgtmgt" onClick={(event => {
                        this.menuclick(event, this.state.mgtmgt)
                    })} href="#" className={this.state.mgtmgt1}>
                        <i className="nav-icon fas fa-bars"/>
                        <p>
                            Item Management
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {siunit}
                            {category}
                            {stock_item}
                            {warehouse}
                        </li>
                    </ul>
                </li>
            }

            let stock_in_req = ""
            let stock_in_app = ""
            let stock_in_rec = ""
            let stock_in_daily = ""
            let stock_inmenu = "";

            let stock_inmgt = this.state.matrix.filter(value => {
                if (value.object_name == "stock_in_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_in_req =
                        <a href={myconfig.urlstock_in_req} className={this.state.stock_in_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Request</p></a>
                    return value;
                }
                if (value.object_name == "stock_in_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_in_app =
                        <a href={myconfig.urlstock_in_app} className={this.state.stock_in_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Approval</p></a>
                    return value;
                }
                if (value.object_name == "stock_in_rec" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_in_rec =
                        <a href={myconfig.urlstock_in_rec} className={this.state.stock_in_rec}><i
                            className="far fa-circle nav-icon"/>
                            <p>Received</p></a>
                    return value;
                }
                if (value.object_name == "stock_in_daily" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_in_daily =
                        <a href={myconfig.urlstock_in_daily} className={this.state.stock_in_daily}><i
                            className="far fa-circle nav-icon"/>
                            <p>Daily</p></a>
                    return value;
                }

            })

            if (stock_inmgt.length > 0) {
                stock_inmenu = <li name="stock_inmgt" onClick={(event => {
                    this.menuclick(event, this.state.stock_inmgt)
                })} className={this.state.stock_inmgt}>
                    <a name="stock_inmgt" onClick={(event => {
                        this.menuclick(event, this.state.stock_inmgt)
                    })} href="#" className={this.state.stock_inmgt1}>
                        <i className="nav-icon fas fa-warehouse"/>
                        <p>
                            Stock IN
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {stock_in_req}
                            {stock_in_app}
                            {stock_in_rec}
                            {stock_in_daily}
                        </li>
                    </ul>
                </li>
            }


            let stock_wastage_in = ""
            let stock_wastage_out = ""
            let stock_wastagemenu = "";

            let stock_wastagemgt = this.state.matrix.filter(value => {
                if (value.object_name == "stock_wastage_in" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_wastage_in =
                        <a href={myconfig.urlstock_wastage_in} className={this.state.stock_wastage_in}><i
                            className="far fa-circle nav-icon"/>
                            <p>Wastage IN</p></a>
                    return value;
                }
                if (value.object_name == "stock_wastage_out" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_wastage_out =
                        <a href={myconfig.urlstock_wastage_out} className={this.state.stock_wastage_out}><i
                            className="far fa-circle nav-icon"/>
                            <p>Wastage OUT</p></a>
                    return value;
                }
            })

            if (stock_wastagemgt.length > 0) {
                stock_wastagemenu = <li name="stock_wastagemgt" onClick={(event => {
                    this.menuclick(event, this.state.stock_wastagemgt)
                })} className={this.state.stock_wastagemgt}>
                    <a name="stock_wastagemgt" onClick={(event => {
                        this.menuclick(event, this.state.stock_wastagemgt)
                    })} href="#" className={this.state.stock_wastagemgt1}>
                        <i className="nav-icon fas fa-trash"/>
                        <p>
                            Stock Wastage
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {stock_wastage_in}
                            {stock_wastage_out}
                        </li>
                    </ul>
                </li>
            }

            let daily_in = ""
            let daily_out = ""
            let daily_transfer = ""
            let dailymenu = "";

            let dailymgt = this.state.matrix.filter(value => {
                if (value.object_name == "daily_in" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    daily_in =
                        <a href={myconfig.urldaily_in} className={this.state.daily_in}><i
                            className="far fa-circle nav-icon"/>
                            <p>In</p></a>
                    return value;
                }
                if (value.object_name == "daily_out" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    daily_out =
                        <a href={myconfig.urldaily_out} className={this.state.daily_out}><i
                            className="far fa-circle nav-icon"/>
                            <p>Out</p></a>
                    return value;
                }
                if (value.object_name == "daily_transfer" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    daily_transfer =
                        <a href={myconfig.urldaily_transfer} className={this.state.daily_transfer}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transfer</p></a>
                    return value;
                }
            })
            if (dailymgt.length > 0) {
                dailymenu = <li name="dailymgt" onClick={(event => {
                    this.menuclick(event, this.state.dailymgt)
                })} className={this.state.dailymgt}>
                    <a name="dailymgt" onClick={(event => {
                        this.menuclick(event, this.state.dailymgt)
                    })} href="#" className={this.state.dailymgt1}>
                        <i className="nav-icon fas fa-check"/>
                        <p>
                            Daily
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {daily_in}
                            {daily_out}
                            {daily_transfer}
                        </li>
                    </ul>
                </li>
            }

            let r_inventory_summary = ""
            let r_inventory_valuation = ""
            let r_fifo_cost_tracking = ""
            let r_stock_summary = ""
            let r_stock_in = ""
            let r_stock_out = ""
            let r_stock_in_wastage = ""
            let r_stock_out_wastage = ""
            let r_stock_transfer = ""
            let r_inventorymenu = "";

            let r_inventorymgt = this.state.matrix.filter(value => {
                if (value.object_name == "r_inventory_summary" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_inventory_summary =
                        <a href={myconfig.urlr_inventory_summary} className={this.state.r_inventory_summary}><i
                            className="far fa-circle nav-icon"/>
                            <p>Inventory Summary</p></a>
                    return value;
                }
                if (value.object_name == "r_inventory_valuation" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_inventory_valuation =
                        <a href={myconfig.urlr_inventory_valuation} className={this.state.r_inventory_valuation}><i
                            className="far fa-circle nav-icon"/>
                            <p>Inventory Valuation</p></a>
                    return value;
                }
                if (value.object_name == "r_fifo_cost_tracking" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_fifo_cost_tracking =
                        <a href={myconfig.urlr_fifo_cost_tracking} className={this.state.r_fifo_cost_tracking}><i
                            className="far fa-circle nav-icon"/>
                            <p>FIFO Cost Tracking</p></a>
                    return value;
                }
             /*   if (value.object_name == "r_stock_summary" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_stock_summary =
                        <a href={myconfig.urlr_stock_summary} className={this.state.r_stock_summary}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Summary</p></a>
                    return value;
                }*/
                if (value.object_name == "r_stock_in" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_stock_in =
                        <a href={myconfig.urlr_stock_in} className={this.state.r_stock_in}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock In</p></a>
                    return value;
                }
                if (value.object_name == "r_stock_out" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_stock_out =
                        <a href={myconfig.urlr_stock_out} className={this.state.r_stock_out}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Out</p></a>
                    return value;
                }
                if (value.object_name == "r_stock_in_wastage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_stock_in_wastage =
                        <a href={myconfig.urlr_stock_in_wastage} className={this.state.r_stock_in_wastage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Wastage In</p></a>
                    return value;
                }
                if (value.object_name == "r_stock_out_wastage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_stock_out_wastage =
                        <a href={myconfig.urlr_stock_out_wastage} className={this.state.r_stock_out_wastage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Wastage Out</p></a>
                    return value;
                }
                if (value.object_name == "r_stock_transfer" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    r_stock_transfer =
                        <a href={myconfig.urlr_stock_transfer} className={this.state.r_stock_transfer}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Transfer</p></a>
                    return value;
                }

            })
            if (r_inventorymgt.length > 0) {
                r_inventorymenu = <li name="r_inventorymgt" onClick={(event => {
                    this.menuclick(event, this.state.r_inventorymgt)
                })} className={this.state.r_inventorymgt}>
                    <a name="r_inventorymgt" onClick={(event => {
                        this.menuclick(event, this.state.r_inventorymgt)
                    })} href="#" className={this.state.r_inventorymgt1}>
                        <i className="nav-icon fas fa-flag"/>
                        <p>
                            Inventory Report
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {r_inventory_summary}
                            {r_inventory_valuation}
                            {r_fifo_cost_tracking}
                            {r_stock_summary}
                            {r_stock_in}
                            {r_stock_out}
                            {r_stock_in_wastage}
                            {r_stock_out_wastage}
                            {r_stock_transfer}
                        </li>
                    </ul>
                </li>
            }


            let s_stock_in = ""
            let s_stock_out = ""
            let s_stock_in_wastage = ""
            let s_stock_out_wastage = ""
            let s_stock_transfer = ""
            let s_stock_balance = ""
            let s_stockmenu = "";

            let s_stockmgt = this.state.matrix.filter(value => {
                if (value.object_name == "s_stock_in" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    s_stock_in =
                        <a href={myconfig.urls_stock_in} className={this.state.s_stock_in}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock IN</p></a>
                    return value;
                }
                if (value.object_name == "s_stock_out" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    s_stock_out =
                        <a href={myconfig.urls_stock_out} className={this.state.s_stock_out}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock OUT</p></a>
                    return value;
                }
                if (value.object_name == "s_stock_in_wastage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    s_stock_in_wastage =
                        <a href={myconfig.urls_stock_in_wastage} className={this.state.s_stock_in_wastage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Wastage In</p></a>
                    return value;
                }
                if (value.object_name == "s_stock_out_wastage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    s_stock_out_wastage =
                        <a href={myconfig.urls_stock_out_wastage} className={this.state.s_stock_out_wastage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Wastage Out</p></a>
                    return value;
                }
                if (value.object_name == "s_stock_transfer" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    s_stock_transfer =
                        <a href={myconfig.urls_stock_transfer} className={this.state.s_stock_transfer}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Transfer</p></a>
                    return value;
                }
                if (value.object_name == "s_stock_balance" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    s_stock_balance =
                        <a href={myconfig.urls_stock_balance} className={this.state.s_stock_balance}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Balance</p></a>
                    return value;
                }

            })
            if (s_stockmgt.length > 0) {
                s_stockmenu = <li name="s_stockmgt" onClick={(event => {
                    this.menuclick(event, this.state.s_stockmgt)
                })} className={this.state.s_stockmgt}>
                    <a name="s_stockmgt" onClick={(event => {
                        this.menuclick(event, this.state.s_stockmgt)
                    })} href="#" className={this.state.s_stockmgt1}>
                        <i className="nav-icon fas fa-check-circle"/>
                        <p>
                            Inventory Summary
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {s_stock_balance}
                            {s_stock_in}
                            {s_stock_out}
                            {s_stock_in_wastage}
                            {s_stock_out_wastage}
                            {s_stock_transfer}
                        </li>
                    </ul>
                </li>
            }




            let stock_out_req = ""
            let stock_out_app = ""
            let stock_out_rec = ""
            let stock_out_daily = ""
            let stock_outmenu = "";

            let stock_outmgt = this.state.matrix.filter(value => {
                if (value.object_name == "stock_out_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_req =
                        <a href={myconfig.urlstock_out_req} className={this.state.stock_out_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Request</p></a>
                    return value;
                }
                if (value.object_name == "stock_out_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_app =
                        <a href={myconfig.urlstock_out_app} className={this.state.stock_out_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Approval</p></a>
                    return value;
                }
                if (value.object_name == "stock_out_rec" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_rec =
                        <a href={myconfig.urlstock_out_rec} className={this.state.stock_out_rec}><i
                            className="far fa-circle nav-icon"/>
                            <p>Received</p></a>
                    return value;
                }
                if (value.object_name == "stock_out_daily" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_out_daily =
                        <a href={myconfig.urlstock_out_daily} className={this.state.stock_out_daily}><i
                            className="far fa-circle nav-icon"/>
                            <p>Daily</p></a>
                    return value;
                }

            })
            if (stock_outmgt.length > 0) {
                stock_outmenu = <li name="stock_outmgt" onClick={(event => {
                    this.menuclick(event, this.state.stock_outmgt)
                })} className={this.state.stock_outmgt}>
                    <a name="stock_outmgt" onClick={(event => {
                        this.menuclick(event, this.state.stock_outmgt)
                    })} href="#" className={this.state.stock_outmgt1}>
                        <i className="nav-icon fas fa-outdent"/>
                        <p>
                            Stock Out
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {stock_out_req}
                            {stock_out_app}
                            {stock_out_rec}
                            {stock_out_daily}
                        </li>
                    </ul>
                </li>
            }


            let inventory_dashboard = ""
            let transaction_d_dashboard = ""
            let transaction_m_dashboard = ""
            let balance_dashboard = ""
            let dashboard_menu = "";

            let dashboardmgt = this.state.matrix.filter(value => {
                if (value.object_name == "inventory_dashboard" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    inventory_dashboard =
                        <a href={myconfig.urlinventory_dashboard} className={this.state.inventory_dashboard}><i
                            className="far fa-circle nav-icon"/>
                            <p>Inventory Dashboard</p></a>
                    return value;
                }
                if (value.object_name == "transaction_d_dashboard" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    transaction_d_dashboard =
                        <a href={myconfig.urltransaction_d_dashboard} className={this.state.transaction_d_dashboard}><i
                            className="far fa-circle nav-icon"/>
                            <p>Daily Transaction</p></a>
                    return value;
                }
                if (value.object_name == "transaction_m_dashboard" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    transaction_m_dashboard =
                        <a href={myconfig.urltransaction_m_dashboard} className={this.state.transaction_m_dashboard}><i
                            className="far fa-circle nav-icon"/>
                            <p>Monthly Transaction</p></a>
                    return value;
                }
                if (value.object_name == "balance_dashboard" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    balance_dashboard =
                        <a href={myconfig.urlbalance_dashboard} className={this.state.balance_dashboard}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Balance</p></a>
                    return value;
                }
            })
            if (dashboardmgt.length > 0) {
                dashboard_menu = <li name="dashboardmgt" onClick={(event => {
                    this.menuclick(event, this.state.dashboardmgt)
                })} className={this.state.dashboardmgt}>
                    <a name="dashboardmgt" onClick={(event => {
                        this.menuclick(event, this.state.dashboardmgt)
                    })} href="#" className={this.state.dashboardmgt1}>
                        <i className="nav-icon fas fa-tachometer-alt"/>
                        <p>
                            Dashboard
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {inventory_dashboard}
                            {transaction_d_dashboard}
                            {transaction_m_dashboard}
                            {balance_dashboard}
                        </li>
                    </ul>
                </li>
            }

            let stock_transfer = null;
            this.state.matrix.filter(value => {
                if (value.object_name == "stock_transfer" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stock_transfer =
                        <li className="nav-item">
                            <a href={myconfig.urlstock_transfer} className="nav-link">
                                <i className="fas fa-circle nav-icon"/>
                                <p>Stock Transfer</p>
                            </a>
                        </li>
                }
            })

            return <>
                {dashboard_menu}
                {dailymenu}
                {usermenu}
                {rolemenu}
                {mgtmenu}
                {stock_inmenu}
                {stock_outmenu}
                {stock_wastagemenu}
                {stock_transfer}
                {r_inventorymenu}
                {s_stockmenu}
                {singlemenu_changepwd}
            </>
        } else {
            return (<></>)
        }
    }
    menuclick = (event, value) => {
        var name = event.target.name;
        if (value == 'nav-item')
            value = 'nav-item menu-open active';
        else
            value = 'nav-item'
        this.setState({
            [name]: value,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    modalcu = () => {
        let displayed = <div className="row">
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>Current Password</label>
                    <input className="form-control" type="password" name="current_password"
                           value={this.state.current_password} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>New Password</label>
                    <input className="form-control" type="password" name="new_password"
                           value={this.state.new_password} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input className="form-control" type="password"
                           name="confirm_password" value={this.state.confirm_password} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-md-12 px-3">
                <div className="form-group d-flex justify-content-around">
                    <button className="btn btn-outline-primary" onClick={this.submitcu}> Save</button>
                    <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel</button>
                </div>
            </div>
        </div>;
        return (<Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Change Password</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {displayed}
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    showcu = (event) => {
        event.preventDefault()
        this.setState({
            cushow: true,
        })
    }
    hidecu = () => {
        this.setState({
            cushow: false,
        })
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.changepwd_by_user;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            current_password: this.state.current_password,
            new_password: this.state.new_password,
            confirm_password: this.state.confirm_password,
            current_pin: this.state.current_pin,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    cushow: false,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    render() {
        return (
            <>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light text-sm">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i
                                className="fas fa-bars"/></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="#" className="nav-link">Contact</a>
                        </li>
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="fullscreen" href="" onClick={this.logout}
                               role="button">
                                <i className="fas fa-sign-out-alt"/>
                            </a>
                        </li>
                    </ul>
                </nav>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <a href="#" className="brand-link text-sm ">
                        <img src="dist/img/tecno/SVG/technostar_logo_white_icon.svg" alt="AdminLTE Logo" className="brand-image"
                             style={{opacity: '.8'}}/>
                        <span className="brand-text font-weight-light">TS</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="info">
                                <a href="#" className="d-block">{this.state.username}</a>
                            </div>
                        </div>
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column text-sm " style={{color:"#ffffff"}} data-widget="treeview"
                                role="menu" data-accordion="false">
                                {this.displaymenus()}
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
                {this.modalcu()}
            </>
        );
    }
}
export default Nav;
