import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Stock_item extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            objsload: false,
            filtered: [],


            id: -1,
            cushow: false,
            iscreate: true,
            txtsearch: '',

            main_form_display: false,
            filed_form_display: false,
            name: '',
            no_fields: 1,

            si_units: [],
            si_unit_selected: null,

            categories: [],
            category_selected: null,

            categories_table: [],
            category_selected_table: null,


            shortage_alert: '',
            excessed_alert: '',
            isexpirable: '',


            filed_name_1: '',
            filed_type_1: '',
            filed_siunit_1: '',

            filed_name_2: '',
            filed_type_2: '',
            filed_siunit_2: '',

            filed_name_3: '',
            filed_type_3: '',
            filed_siunit_3: '',

            filed_name_4: '',
            filed_type_4: '',
            filed_siunit_4: '',

            filed_name_5: '',
            filed_type_5: '',
            filed_siunit_5: '',

            filed_name_6: '',
            filed_type_6: '',
            filed_siunit_6: '',

            filed_name_7: '',
            filed_type_7: '',
            filed_siunit_7: '',

            filed_name_8: '',
            filed_type_8: '',
            filed_siunit_8: '',

            filed_name_9: '',
            filed_type_9: '',
            filed_siunit_9: '',

            filed_name_10: '',
            filed_type_10: '',
            filed_siunit_10: '',

            filed_name_11: '',
            filed_type_11: '',
            filed_siunit_11: '',

            filed_name_12: '',
            filed_type_12: '',
            filed_siunit_12: '',

            filed_name_13: '',
            filed_type_13: '',
            filed_siunit_13: '',

            filed_name_14: '',
            filed_type_14: '',
            filed_siunit_14: '',

            filed_name_15: '',
            filed_type_15: '',
            filed_siunit_15: '',

            //1 is create,2 is update 3 is show
            type: '',
        }
    }

    componentDidMount() {
        this.fetcheddata()
    }

    reset = () => {
        this.setState({
            objs: [],
            objsload: false,
            filtered: [],


            id: -1,
            cushow: false,
            iscreate: true,
            txtsearch: '',
            name: '',
            no_fields: 1,

        })
    }
    fetcheddata = () => {
        this.reset();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_stock_items_obj;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    si_units: res.data.siunits,
                    categories: res.data.categories,
                    categories_table: res.data.categories_table,
                    /* filtered:res.data.datas,
                     objsload: true,*/
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    handelchange_si_unit = (si_unit_selected) => {
        this.setState({
            si_unit_selected: si_unit_selected,
        })
    }
    handelchange_category = (category_selected) => {
        this.setState({
            category_selected: category_selected,
        })
    }
    handelchange_category_table = (category_selected_table) => {
        this.setState({
            objs: [],
            filtered: [],
            objsload: false,
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_stock_items;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: category_selected_table.value,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered: res.data.datas,
                    objsload: true,
                    category_selected_table: category_selected_table,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showform = (event, iscreate, id = -1) => {
        event.preventDefault()
        if (iscreate) {
            this.setState({
                id: id,
                iscreate: iscreate,
                main_form_display: true,
                filed_form_display: false,
                type: 1,
            })
        } else {
            let baseurl = myconfig.get_siunit;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        console.log(res.data.data.name)
                        this.setState({
                            cushow: true,
                            id: id,
                            iscreate: iscreate,
                            name: res.data.data.name,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    showform_show = (event, id = -1) => {
        event.preventDefault()

        let baseurl = myconfig.get_stock_item;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.data == null) {
                    Swal.fire({
                        title: 'Error!',
                        text: "No data found",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        id: id,
                        main_form_display: true,
                        filed_form_display: true,
                        type: 3,
                        name: res.data.data.name,
                        si_unit_selected: res.data.data.siunit_obj,
                        category_selected: res.data.data.category_obj,

                        no_fields: res.data.data.number_of_filed,
                        filed_name_1: res.data.data.filed_name_1,
                        filed_type_1: res.data.data.filed_type_1,
                        filed_siunit_1: res.data.data.filed_siunit_1,

                        filed_name_2: res.data.data.filed_name_2,
                        filed_type_2: res.data.data.filed_type_2,
                        filed_siunit_2: res.data.data.filed_siunit_2,

                        filed_name_3: res.data.data.filed_name_3,
                        filed_type_3: res.data.data.filed_type_3,
                        filed_siunit_3: res.data.data.filed_siunit_3,

                        filed_name_4: res.data.data.filed_name_4,
                        filed_type_4: res.data.data.filed_type_4,
                        filed_siunit_4: res.data.data.filed_siunit_4,

                        filed_name_5: res.data.data.filed_name_5,
                        filed_type_5: res.data.data.filed_type_5,
                        filed_siunit_5: res.data.data.filed_siunit_5,

                        filed_name_6: res.data.data.filed_name_6,
                        filed_type_6: res.data.data.filed_type_6,
                        filed_siunit_6: res.data.data.filed_siunit_6,

                        filed_name_7: res.data.data.filed_name_7,
                        filed_type_7: res.data.data.filed_type_7,
                        filed_siunit_7: res.data.data.filed_siunit_7,

                        filed_name_8: res.data.data.filed_name_8,
                        filed_type_8: res.data.data.filed_type_8,
                        filed_siunit_8: res.data.data.filed_siunit_8,

                        filed_name_9: res.data.data.filed_name_9,
                        filed_type_9: res.data.data.filed_type_9,
                        filed_siunit_9: res.data.data.filed_siunit_9,

                        filed_name_10: res.data.data.filed_name_10,
                        filed_type_10: res.data.data.filed_type_10,
                        filed_siunit_10: res.data.data.filed_siunit_10,

                        filed_name_11: res.data.data.filed_name_11,
                        filed_type_11: res.data.data.filed_type_11,
                        filed_siunit_11: res.data.data.filed_siunit_11,

                        filed_name_12: res.data.data.filed_name_12,
                        filed_type_12: res.data.data.filed_type_12,
                        filed_siunit_12: res.data.data.filed_siunit_12,

                        filed_name_13: res.data.data.filed_name_13,
                        filed_type_13: res.data.data.filed_type_13,
                        filed_siunit_13: res.data.data.filed_siunit_13,

                        filed_name_14: res.data.data.filed_name_14,
                        filed_type_14: res.data.data.filed_type_14,
                        filed_siunit_14: res.data.data.filed_siunit_14,

                        filed_name_15: res.data.data.filed_name_15,
                        filed_type_15: res.data.data.filed_type_15,
                        filed_siunit_15: res.data.data.filed_siunit_15,

                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    hideform = (event) => {
        event.preventDefault()
        this.setState({
            main_form_display: false,
            filed_form_display: false,
        })
    }
    hidecu = () => {
        this.setState({
            cushow: false,
        })
    }
    modalcu = () => {
        return (<Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>SI_Unit Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>SI_Unit</label>
                                                <input className="form-control" type="text" name="name"
                                                       onChange={this.inputmgt} value={this.state.name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary"
                                                        onClick={this.submitcu}> Save
                                                </button>
                                                <button className="btn btn-outline-danger"
                                                        onClick={this.hidecu}> Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_siunit;
        if (this.state.iscreate)
            baseurl = myconfig.create_siunit;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: this.state.id,
            name: this.state.name,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata = () => {
        let retdata = null;
        if (this.state.objsload) {
            let count = 0;
            retdata = this.state.filtered.map(value => {
                let edit = null;
                if (this.state.objs.usermatrix.update == true) {
                    edit = <td><a href="" onClick={(event => {
                        this.showform(event, false, value.id)
                    })}>Edit</a></td>
                }
                count = count + 1
                return (<tr>
                    <td>{count}</td>
                    {edit}
                    <td><a href="" onClick={(event => {
                        this.showform_show(event, value.id)
                    })}>Show</a></td>
                    <td>{value.name}</td>
                    <td>{value.cateory}</td>
                    <td>{value.siunit}</td>
                    <td>{value.number_of_filed}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    display_form_main_2 = () => {
        let display = null;
        if (this.state.main_form_display) {
            let header = <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                <h3 className="card-title">New Stock Item</h3>
                <div className="card-tools">
                    <button type="button" onClick={this.hideform} className="btn btn-tool">
                        <i className="fas fa-times"/>
                    </button>
                </div>
            </div>
            let create_filed = <div className="form-group">
                <button onClick={this.btncreate_fileds} type="submit" className="btn btn-warning">Create Fields</button>
            </div>
            let create_stock_item = <div className="card-footer">
                <form action="#" method="post">
                    <button onClick={this.btncreate} type="button" className="btn btn-block btn-primary">Create Stock
                        Item
                    </button>
                </form>
            </div>
            if (this.state.type == "2") {
                header = <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Update Stock Item</h3>
                    <div className="card-tools">
                        <button type="button" onClick={this.hideform} className="btn btn-tool">
                            <i className="fas fa-times"/>
                        </button>
                    </div>
                </div>
                create_filed = null;
                create_stock_item = <div className="card-footer">
                    <form action="#" method="post">
                        <button onClick={this.btncreate} type="button" className="btn btn-block btn-primary">Update
                            Stock Item
                        </button>
                    </form>
                </div>
            } else if (this.state.type == "3") {
                header = <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Show Stock Item</h3>
                    <div className="card-tools">
                        <button type="button" onClick={this.hideform} className="btn btn-tool">
                            <i className="fas fa-times"/>
                        </button>
                    </div>
                </div>
                create_filed = null
                create_stock_item = null
            }
            display = (
                <>
                    <div className="col-md-6">
                        <div className="card">
                            {header}
                            <form>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Name</label>
                                        <input onChange={this.inputmgt} type="text" className="form-control" name="name"
                                               value={this.state.name}
                                               placeholder="Stock name"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Category </label>
                                        <Select
                                            value={this.state.category_selected}
                                            onChange={this.handelchange_category}
                                            options={this.state.categories}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>SI_Unit </label>
                                        <Select
                                            value={this.state.si_unit_selected}
                                            onChange={this.handelchange_si_unit}
                                            options={this.state.si_units}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label># of Fields</label>
                                        <input onChange={this.inputmgt} type="number" min={0} max={15}
                                               className="form-control" name="no_fields" value={this.state.no_fields}
                                        />
                                    </div>
                                    {create_filed}
                                </div>
                            </form>
                            {create_stock_item}
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </>
            )
        }
        return (
            <>
                {display}
            </>
        )
    }
    display_form_main = () => {
        let display = null;
        if (this.state.main_form_display) {
            let header = <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                <h3 className="card-title">New Stock Item</h3>
                <div className="card-tools">
                    <button type="button" onClick={this.hideform} className="btn btn-tool">
                        <i className="fas fa-times"/>
                    </button>
                </div>
            </div>
            let create_filed = <div className="form-group">
                <button onClick={this.btncreate_fileds} type="submit" className="btn btn-warning">Create Fields</button>
            </div>
            let create_stock_item = <div className="card-footer">
                <form action="#" method="post">
                    <button onClick={this.btncreate} type="button" className="btn btn-block btn-primary">Create Stock
                        Item
                    </button>
                </form>
            </div>
            if (this.state.type == "2") {
                header = <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Update Stock Item</h3>
                    <div className="card-tools">
                        <button type="button" onClick={this.hideform} className="btn btn-tool">
                            <i className="fas fa-times"/>
                        </button>
                    </div>
                </div>
                create_filed = null;
                create_stock_item = <div className="card-footer">
                    <form action="#" method="post">
                        <button onClick={this.btncreate} type="button" className="btn btn-block btn-primary">Update
                            Stock Item
                        </button>
                    </form>
                </div>
            } else if (this.state.type == "3") {
                header = <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Show Stock Item</h3>
                    <div className="card-tools">
                        <button type="button" onClick={this.hideform} className="btn btn-tool">
                            <i className="fas fa-times"/>
                        </button>
                    </div>
                </div>
                create_filed = null
                create_stock_item = null
            }
            display = (
                <>
                    <div className="col-md-12">
                        <div className="card">
                            {header}
                            <form>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Name</label>
                                                <input onChange={this.inputmgt} type="text" className="form-control"
                                                       name="name" value={this.state.name}
                                                       placeholder="Stock name"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Category </label>
                                                <Select
                                                    value={this.state.category_selected}
                                                    onChange={this.handelchange_category}
                                                    options={this.state.categories}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>SI_Unit </label>
                                                <Select
                                                    value={this.state.si_unit_selected}
                                                    onChange={this.handelchange_si_unit}
                                                    options={this.state.si_units}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label># of Fields</label>
                                                <input onChange={this.inputmgt} type="number" min={0} max={15}
                                                       className="form-control" name="no_fields"
                                                       value={this.state.no_fields}
                                                />
                                            </div>
                                            {create_filed}
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Shortage</label>
                                                <input onChange={this.inputmgt} type="number" min={0}
                                                       className="form-control" name="shortage_alert"
                                                       value={this.state.shortage_alert}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Exceeded</label>
                                                <input onChange={this.inputmgt} type="number" min={0}
                                                       className="form-control" name="excessed_alert"
                                                       value={this.state.excessed_alert}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Is Expiring</label>
                                                <select name="isexpirable" value={this.state.isexpirable}
                                                        onChange={this.inputmgt} className="form-control">
                                                    <option value="-1">--Is Expiring--</option>
                                                    <option value="1">Yes</option>
                                                    <option value="2">No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {create_stock_item}
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </>
            )
        }
        return (
            <>
                {display}
            </>
        )
    }
    display_form_filed = () => {
        let display = null;
        if (this.state.filed_form_display) {
            if (this.state.no_fields == 0)
                display = null
            else {
                display = null
                if (isNaN(this.state.no_fields)) {
                    Swal.fire({
                        title: 'Error!',
                        text: "Fileds must be numeric",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else if (this.state.no_fields == "") {
                    Swal.fire({
                        title: 'Error!',
                        text: "Fileds number is required",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else if (this.state.no_fields < 0 || this.state.no_fields > 15) {
                    Swal.fire({
                        title: 'Error!',
                        text: "Fileds must be range between 0 to 15",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let arr = [];
                    for (let i = 0; i < this.state.no_fields; i++) {
                        arr.push(i + 1)
                    }
                    let isgreen = true;
                    let d = arr.map(v => {
                        let name = "filed_name_" + v;
                        let type = "filed_type_" + v;
                        let siunit = "filed_siunit_" + v;
                        let cla = "card card-green";
                        if (!isgreen)
                            cla = "card card-red"
                        isgreen = !isgreen;
                        return (
                            <div className="col-md-4">
                                <div className={cla}>
                                    <div className="card-header">
                                        <h3 className="card-title">Filed {v}</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Filed_Name_{v}</label>
                                            <input value={this.state[name]} onChange={this.inputmgt} name={name} val
                                                   type="text" className="form-control" placeholder={"Filed_Name_" + v}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Filed_Type_{v}</label>
                                            <select value={this.state[type]} onChange={this.inputmgt} name={type}
                                                    className="form-control">
                                                <option value="-1">--Select Type--</option>
                                                <option value="NUMERIC">Numeric</option>
                                                <option value="TEXT">Text</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Filed_SI_Unit_{v}</label>
                                            <input value={this.state[siunit]} onChange={this.inputmgt} name={siunit}
                                                   type="text" className="form-control" placeholder={"Filed_SI_Unit_" + v}/>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    })
                    display = (
                        <>
                            <div className="row">
                                {d}
                            </div>
                        </>
                    )
                }


            }

        }
        return (
            <>
                {display}
            </>
        )
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }

    btncreate_fileds = (event) => {
        event.preventDefault()
        if (isNaN(this.state.no_fields)) {
            Swal.fire({
                title: 'Error!',
                text: "Fileds must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.no_fields == "") {
            Swal.fire({
                title: 'Error!',
                text: "Fileds number is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.no_fields < 0 || this.state.no_fields > 15) {
            Swal.fire({
                title: 'Error!',
                text: "Fileds must be range between 0 to 15",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            this.setState({
                filed_form_display: true,
            })
        }
    }
    btncreate = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_siunit;
        if (this.state.iscreate)
            baseurl = myconfig.create_stock_item;
        if (this.state.category_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Category is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.si_unit_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "SI_Unit is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.isexpirable == "-1") {
            Swal.fire({
                title: 'Error!',
                text: "Expire is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: this.state.id,
                name: this.state.name,
                si_unit: this.state.si_unit_selected.value,
                category: this.state.category_selected.value,
                no_fields: this.state.no_fields,
                shortage_alert: this.state.shortage_alert,
                excessed_alert: this.state.excessed_alert,
                isexpirable: this.state.isexpirable,
                filed_name_1: this.state.filed_name_1,
                filed_type_1: this.state.filed_type_1,
                filed_siunit_1: this.state.filed_siunit_1,
                filed_name_2: this.state.filed_name_2,
                filed_type_2: this.state.filed_type_2,
                filed_siunit_2: this.state.filed_siunit_2,
                filed_name_3: this.state.filed_name_3,
                filed_type_3: this.state.filed_type_3,
                filed_siunit_3: this.state.filed_siunit_3,
                filed_name_4: this.state.filed_name_4,
                filed_type_4: this.state.filed_type_4,
                filed_siunit_4: this.state.filed_siunit_4,
                filed_name_5: this.state.filed_name_5,
                filed_type_5: this.state.filed_type_5,
                filed_siunit_5: this.state.filed_siunit_5,
                filed_name_6: this.state.filed_name_6,
                filed_type_6: this.state.filed_type_6,
                filed_siunit_6: this.state.filed_siunit_6,
                filed_name_7: this.state.filed_name_7,
                filed_type_7: this.state.filed_type_7,
                filed_siunit_7: this.state.filed_siunit_7,
                filed_name_8: this.state.filed_name_8,
                filed_type_8: this.state.filed_type_8,
                filed_siunit_8: this.state.filed_siunit_8,
                filed_name_9: this.state.filed_name_9,
                filed_type_9: this.state.filed_type_9,
                filed_siunit_9: this.state.filed_siunit_9,
                filed_name_10: this.state.filed_name_10,
                filed_type_10: this.state.filed_type_10,
                filed_siunit_10: this.state.filed_siunit_10,
                filed_name_11: this.state.filed_name_11,
                filed_type_11: this.state.filed_type_11,
                filed_siunit_11: this.state.filed_siunit_11,
                filed_name_12: this.state.filed_name_12,
                filed_type_12: this.state.filed_type_12,
                filed_siunit_12: this.state.filed_siunit_12,
                filed_name_13: this.state.filed_name_13,
                filed_type_13: this.state.filed_type_13,
                filed_siunit_13: this.state.filed_siunit_13,
                filed_name_14: this.state.filed_name_14,
                filed_type_14: this.state.filed_type_14,
                filed_siunit_14: this.state.filed_siunit_14,
                filed_name_15: this.state.filed_name_15,
                filed_type_15: this.state.filed_type_15,
                filed_siunit_15: this.state.filed_siunit_15,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(value => {
                        this.fetcheddata()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    render() {
        let edit = null;
        if (this.state.objsload) {
            if (this.state.objs.usermatrix.update == true) {
                edit = <th width="11.1%">Action</th>
            }
        }
        return (
            <>
                <Nav type="stock_item"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock Item Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Stock Item</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {this.display_form_main()}
                                <div className="col-12">
                                    {this.display_form_filed()}
                                </div>
                                <div className="col-12">
                                    <div className="card">
                             {/*           <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block"
                                                            onClick={(event => this.showform(event, true))}>New Stock
                                                        Item
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <div className="row">
                                                <div className="col-md-3 my-2 mx-2">
                                                    <div className="form-group">
                                                        <label>Category </label>
                                                        <Select
                                                            value={this.state.category_selected_table}
                                                            onChange={this.handelchange_category_table}
                                                            options={this.state.categories_table}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <table className="table text-nowrap">
                                                        <thead>
                                                        <tr>
                                                            <th width="5.5%">#</th>
                                                            {edit}
                                                            <th>Show</th>
                                                            <th>Name</th>
                                                            <th>Category</th>
                                                            <th>SI_Unit</th>
                                                            <th># Of Fileds</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.tabledata()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Stock_item;
