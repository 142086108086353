import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import {Modal} from "react-bootstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
let source = axios.CancelToken.source();
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
class Inventory_dashboard extends Component {

    constructor() {
        super();
        this.state = {
            inventory_summary_load: false,
            inventory_summary_obj: null,
        }
    }

    componentDidMount() {
        this.fetcheddata();
    }

    fetcheddata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_inventory_dashboard;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    inventory_summary_load: true,
                    inventory_summary_obj: res.data,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inventory_summary_display = () => {
        let out_of_stock = "NA";
        let approching_out_of_stock = "NA";
        let expire_stock = "NA";
        let approching_expire_stock = "NA";
        let excesses_stocks = "NA";
        if (this.state.inventory_summary_load) {
            out_of_stock = this.state.inventory_summary_obj.inventory_summary.out_of_stock;
            approching_out_of_stock = this.state.inventory_summary_obj.inventory_summary.approching_out_of_stock;
            expire_stock = this.state.inventory_summary_obj.inventory_summary.expire_stock;
            approching_expire_stock = this.state.inventory_summary_obj.inventory_summary.appproching_expire_stock;
            excesses_stocks = this.state.inventory_summary_obj.inventory_summary.excesses_stocks;
        }
        return <div className="col-md-4">
            <div className="card">
                <div className="card-header border-0">
                    <h3 className="card-title">Inventory Summary</h3>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                        <p className="text-danger text-xl">
                            <i className="fa fa-box-open"/>
                        </p>
                        <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
               {out_of_stock}
            </span>
                            <span className="text-muted">OUT OF STOCK ITEMS</span>
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                        <p className="text-warning text-xl">
                            <i className="fa fa-box-open"/>
                        </p>
                        <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
            {approching_out_of_stock}
            </span>
                            <span className="text-muted">APPROACHING OUT OF STOCKS</span>
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                        <p className="text-success text-xl">
                            <i className="fa fa-cart-plus"/>
                        </p>
                        <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
                {excesses_stocks}
            </span>
                            <span className="text-muted">Excesses Stocks</span>
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                        <p className="text-danger text-xl">
                            <i className="fa fa-trash"/>
                        </p>
                        <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
 {expire_stock}
            </span>
                            <span className="text-muted">Expire Stocks</span>
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                        <p className="text-warning text-xl">
                            <i className="fa fa-box-open"/>
                        </p>
                        <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
            {approching_expire_stock}
            </span>
                            <span className="text-muted">APPROACHING EXPIRE STOCKS</span>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    }
    out_of_stock_display = () => {
        let tabledata = null;
        if (this.state.inventory_summary_load) {
            tabledata = this.state.inventory_summary_obj.out_of_stock_items.map(value => {
                return <tr>
                    <td>{value.cateory_name}</td>
                    <td>{value.name}</td>
                    <td>{value.siunit_name}</td>
                    <td>{value.balance}</td>
                </tr>
            })
        }
        return <div className="col-md-6">
            <div className="card">
                <div className="card-header bg-danger border-0">
                    <h3 className="card-title">OUT OF STOCK ITEMS</h3>
                </div>
                <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th className="col-md-4">Category</th>
                            <th className="col-md-3">Item Name</th>
                            <th className="col-md-2">Unit</th>
                            <th className="col-md-3">Stock On Hand</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tabledata}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    approching_out_of_stock_display = () => {
        let tabledata = null;
        if (this.state.inventory_summary_load) {
            tabledata = this.state.inventory_summary_obj.approching_out_of_stock.map(value => {
                return <tr>
                    <td>{value.cateory_name}</td>
                    <td>{value.name}</td>
                    <td>{value.siunit_name}</td>
                    <td>{value.shortage_alert}</td>
                    <td>{value.balance}</td>
                </tr>
            })
        }
        return <div className="col-md-6">
            <div className="card">
                <div className="card-header bg-warning border-0">
                    <h3 className="card-title">APPROACHING OUT OF STOCKS</h3>
                </div>
                <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th className="col-md-3">Category</th>
                            <th className="col-md-2">Item Name</th>
                            <th className="col-md-2">Unit</th>
                            <th className="col-md-2">Limit</th>
                            <th className="col-md-3">Stock On Hand</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tabledata}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    exccess_stock_display = () => {
        let tabledata = null;
        if (this.state.inventory_summary_load) {
            tabledata = this.state.inventory_summary_obj.excesses_stocks.map(value => {
                return <tr>
                    <td>{value.cateory_name}</td>
                    <td>{value.name}</td>
                    <td>{value.siunit_name}</td>
                    <td>{value.shortage_alert}</td>
                    <td>{value.balance}</td>
                </tr>
            })
        }
        return <div className="col-md-6">
            <div className="card">
                <div className="card-header bg-success border-0">
                    <h3 className="card-title">EXCESS STOCKS</h3>
                </div>
                <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th className="col-md-3">Category</th>
                            <th className="col-md-2">Item Name</th>
                            <th className="col-md-2">Unit</th>
                            <th className="col-md-2">Limit</th>
                            <th className="col-md-3">Stock On Hand</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tabledata}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    approching_expire_stock_display = () => {
        let tabledata = null;
        if (this.state.inventory_summary_load) {
            tabledata = this.state.inventory_summary_obj.appproching_expire_stock.map(value => {
                return <tr>
                    <td>{value.cateory_name}</td>
                    <td>{value.name}</td>
                    <td>{value.spec}</td>
                    <td>{value.expire_date}</td>
                    <td>{value.siunit_name}</td>
                    <td>{value.shortage_alert}</td>
                    <td>{value.balance}</td>
                </tr>
            })
        }
        return <div className="col-md-6">
            <div className="card">
                <div className="card-header border-0 bg-warning">
                    <h3 className="card-title">APPROACHING EXPIRE STOCKS</h3>
                </div>
                <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th className="col-md-2">Category</th>
                            <th className="col-md-2">Item Name</th>
                            <th className="col-md-2">Spec</th>
                            <th className="col-md-2">Expire Date</th>
                            <th className="col-md-1">Unit</th>
                            <th className="col-md-2">Limit</th>
                            <th className="col-md-1">Stock On Hand</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tabledata}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    expire_stock_display = () => {
        let tabledata = null;
        if (this.state.inventory_summary_load) {
            tabledata = this.state.inventory_summary_obj.expire_stock.map(value => {
                return <tr>
                    <td>{value.cateory_name}</td>
                    <td>{value.name}</td>
                    <td>{value.spec}</td>
                    <td>{value.expire_date}</td>
                    <td>{value.siunit_name}</td>
                    <td>{value.shortage_alert}</td>
                    <td>{value.balance}</td>
                </tr>
            })
        }
        return <div className="col-md-6">
            <div className="card">
                <div className="card-header border-0 bg-danger">
                    <h3 className="card-title">Expire STOCKS</h3>
                </div>
                <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th className="col-md-2">Category</th>
                            <th className="col-md-2">Item Name</th>
                            <th className="col-md-2">Spec</th>
                            <th className="col-md-2">Expire Date</th>
                            <th className="col-md-1">Unit</th>
                            <th className="col-md-2">Limit</th>
                            <th className="col-md-1">Stock On Hand</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tabledata}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

    render() {
        var display=null;
        if (this.state.inventory_summary_load) {
            console.log(this.state.inventory_summary_obj.chart.labels)
            const labels=this.state.inventory_summary_obj.chart.labels;
            const options= {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Stock with Valuation',
                    },
                },
            };
            const data = {
                labels,
                datasets: [
                    {
                        label: this.state.inventory_summary_obj.chart.ins.label,
                        data: this.state.inventory_summary_obj.chart.ins.data,
                        borderColor: 'rgb(123, 12, 232)',
                        backgroundColor: 'rgba(123, 99, 132, 0.5)',
                    },
                    {
                        label: this.state.inventory_summary_obj.chart.outs.label,
                        data: this.state.inventory_summary_obj.chart.outs.data,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                    {
                        label: this.state.inventory_summary_obj.chart.wastages.label,
                        data: this.state.inventory_summary_obj.chart.wastages.data,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ],
            };
            display=  <div className="card-body">
                <Line options={options} data={data}/>
            </div>
        }
        return (
            <>
                <Nav type="inventory_dashboard"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Inventory Dashboard</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Dashboard_1</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {this.inventory_summary_display()}
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header border-0">
                                            <h3 className="card-title">Inventory Summary Chart</h3>
                                        </div>
                                        {display}
                                    </div>

                                </div>
                                {this.out_of_stock_display()}
                                {this.approching_out_of_stock_display()}
                                {this.exccess_stock_display()}
                                {this.approching_expire_stock_display()}
                                {this.expire_stock_display()}

                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Inventory_dashboard;
