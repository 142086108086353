import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';
import Footer from "./Footer";

let source = axios.CancelToken.source();

class Welcome extends Component {
    constructor() {
        super();
        this.state = {
            sessionchk: false,
            useraccess: null,
        }
    }
    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    render() {
        return (
            <>
                <>
                    <Nav useraccess={this.state.useraccess}/>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0">Home</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active"></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-primary card-outline">
                                            <div className="card-header" style={{backgroundColor:"#E1C16E",color:"black"}}>
                                                <h5 className="m-0 text-center text-bold">WELCOME TO TECHNO STORE MANAGEMENT SYSTEM</h5>
                                            </div>
                                            <div className="card-body text-center">
                                                <h6>We are here to help you.</h6>
                                                <p className="card-text pt-2">This system is developed by Technostar IT Solution P.L.C</p>
                                                <a href="#" className="btn btn-primary">Getting Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer/>
                </>
            </>
        )
    }
}

export default Welcome;
