import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Daily_transfer extends Component {

    constructor() {
        super();
        this.state = {
            st:new Date().toISOString().slice(0,10),
            ed:new Date().toISOString().slice(0,10),
            obj:[],
            objload:false,
            type:1,
        }
    }

    componentDidMount() {
        this.fetcheddata(1)
    }

    fetcheddata = (type) => {
        this.setState({
            obj:[],
            objload:false,
            type:type,
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_daily_transfer+"?type="+type;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj:res.data,
                    objload:true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            obj:[],
            objload:false,
        })
    }
    btn_fetch = (event,type) => {
        event.preventDefault();
        this.fetcheddata(type)
    }

    tabledata = () => {
        let retdata = null;
        if (this.state.objload) {
            let count = 0;
            let datas = this.state.obj.reports.map(v => {
                count=count+1;
                return (<tr>
                        <td>{count}</td>
                        <td>{v.stockitem_name}</td>
                        <td>{v.by}</td>
                        <td>{v.from}</td>
                        <td>{v.to}</td>
                        <td>{v.at}</td>
                        <td>{v.qty}</td>
                    </tr>
                )
            });
            retdata = <table className="table">
                <thead>
                <th className="col-md-1">#</th>
                <th className="col-md-2">Stock Name</th>
                <th className="col-md-2">By</th>
                <th className="col-md-1">From</th>
                <th className="col-md-1">To</th>
                <th className="col-md-2">At</th>
                <th className="col-md-1">Quantity</th>
                </thead>
                <tbody>
                {datas}
                </tbody>
            </table>
        }
        return (<>{retdata}</>)
    }

    render() {
        return (
            <>
                <Nav type="daily_transfer"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Daily Stock Transfer</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Transfer</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">
                                                <i className="fas fa-chart-pie mr-1" />
                                                Transaction Summary
                                            </h3>
                                            <div className="card-tools">
                                                <ul className="nav nav-pills ml-auto">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" href="" onClick={(event => this.btn_fetch(event,1))} data-toggle="tab">Today</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="" onClick={(event => this.btn_fetch(event,2))} data-toggle="tab">Yesterday</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{height: '550px', width: '100%'}}>
                                            {this.tabledata()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Daily_transfer;
