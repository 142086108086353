import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Warehouse extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            objsload: false,
            filtered:[],


            id:-1,
            cushow:false,
            iscreate:true,
            txtsearch:'',

            name:'',
            location:'',
            contact_name:'',
            contact_phone:'',

        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    reset=()=>{
        this.setState({
            objs: [],
            objsload: false,
            filtered:[],


            id:-1,
            cushow:false,
            iscreate:true,
            txtsearch:'',
            name:'',
            location:'',
            contact_name:'',
            contact_phone:'',
        })
    }
    fetcheddata = () => {
        this.reset();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_warehouses;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered:res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        if(iscreate) {
            this.setState({
                cushow: true,
                id: id,
                iscreate: iscreate,
                name:'',
                location:'',
                contact_name:'',
                contact_phone:'',
            })
        }
        else {
            let baseurl = myconfig.get_warehouse;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        console.log(res.data.data.name)
                        this.setState({
                            cushow: true,
                            id:id,
                            iscreate: iscreate,
                            name:res.data.data.name,
                            location:res.data.data.location,
                            contact_name:res.data.data.contact_name,
                            contact_phone:res.data.data.contact_phone,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Warehouse Management</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-md-6 px-3">
                                            <div className="form-group">
                                                <label>Warehouse Name</label>
                                                <input placeholder="Warehouse Name" className="form-control" type="text" name="name" onChange={this.inputmgt} value={this.state.name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <div className="form-group">
                                                <label>Location</label>
                                                <input placeholder="Location" className="form-control" type="text" name="location" onChange={this.inputmgt} value={this.state.location}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <div className="form-group">
                                                <label>Contact Name</label>
                                                <input placeholder="Contact Name" className="form-control" type="text" name="contact_name" onChange={this.inputmgt} value={this.state.contact_name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <div className="form-group">
                                                <label>Contact Phone</label>
                                                <input placeholder="Contact Phone" className="form-control" type="text" name="contact_phone" onChange={this.inputmgt} value={this.state.contact_phone}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_warehouse;
        if (this.state.iscreate)
            baseurl = myconfig.create_warehouse;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            id: this.state.id,
            name:this.state.name,
            location:this.state.location,
            contact_name:this.state.contact_name,
            contact_phone:this.state.contact_phone,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            retdata = this.state.filtered.map(value => {
                let edit=null;
                if(this.state.objs.usermatrix.update==true){
                    edit= <td><a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a></td>
                }
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    {edit}
                    <td>{value.name}</td>
                    <td>{value.location}</td>
                    <td>{value.contact_name}</td>
                    <td>{value.contact_phone}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }

    render() {
        let edit=null;
        if(this.state.objsload){
            if(this.state.objs.usermatrix.update==true){
                edit=<th width="11.1%">Action</th>
            }
        }
        return (
            <>
                <Nav type="warehouse"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Warehouse Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Warehouse</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right" value={this.state.txtsearch} onChange={this.inputsearch} placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block" onClick={(event => this.showcu(event,true))}>New Warehouse</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    {edit}
                                                    <th>Name</th>
                                                    <th>Location</th>
                                                    <th>Contact Name</th>
                                                    <th>Contact Phone</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Warehouse;
