import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Welcome from "./Componet/Welcome";
import User from "./Componet/User/User";
import ObjUser from "./Componet/User/ObjUser";
import UserAccess1 from "./Componet/User/UserAccess1";
import UserAccess2 from "./Componet/User/UserAccess2";
import Login from "./Componet/Login";
import Siunit from "./Componet/Management/Siunit";
import Category from "./Componet/Management/Category";
import Stock_item from "./Componet/Management/Stock_item";
import Warehouse from "./Componet/Management/Warehouse";
import Stock_in_req from "./Componet/Stock_IN/Stock_in_req";
import Stock_in_app from "./Componet/Stock_IN/Stock_in_app";
import Stock_in_rec from "./Componet/Stock_IN/Stock_in_rec";
import Stock_in_daily from "./Componet/Stock_IN/Stock_in_daily";
import Stock_out_req from "./Componet/Stock_OUT/Stock_out_req";
import Stock_out_app from "./Componet/Stock_OUT/Stock_out_app";
import Stock_out_rec from "./Componet/Stock_OUT/Stock_out_rec";
import Stock_out_daily from "./Componet/Stock_OUT/Stock_out_daily";
import Stock_wastage_in from "./Componet/Stock_wastage/Stock_wastage_in";
import Stock_wastage_out from "./Componet/Stock_wastage/Stock_wastage_out";
import Stock_transfer from "./Componet/Stock_TRANSFER/Stock_transfer";
import R_inventory_summary from "./Componet/Inventory_Report/R_inventory_summary";
import R_inventory_valuation from "./Componet/Inventory_Report/R_inventory_valuation";
import R_fifo_cost_tracking from "./Componet/Inventory_Report/R_fifo_cost_tracking";
import R_stock_summary from "./Componet/Inventory_Report/R_stock_summary";
import Inventory_dashboard from "./Componet/Dashboard/Inventory_dashboard";
import Transaction_d_dashboard from "./Componet/Dashboard/Transaction_d_dashboard";
import Transaction_m_dashboard from "./Componet/Dashboard/Transaction_m_dashboard";
import Balance_dashboard from "./Componet/Dashboard/Balance_dashboard";
import Warehouse_role from "./Componet/Role/Warehouse_role";
import Approval_role from "./Componet/Role/Approval_role";
import R_stock_in from "./Componet/Inventory_Report/R_stock_in";
import R_stock_out from "./Componet/Inventory_Report/R_stock_out";
import R_stock_in_wastage from "./Componet/Inventory_Report/R_stock_in_wastage";
import R_stock_out_wastage from "./Componet/Inventory_Report/R_stock_out_wastage";
import R_stock_transfer from "./Componet/Inventory_Report/R_stock_transfer";
import Daily_in from "./Componet/Daily/Daily_in";
import Daily_out from "./Componet/Daily/Daily_out";
import Daily_transfer from "./Componet/Daily/Daily_transfer";
import S_stock_in from "./Componet/Summary_Stock/S_stock_in";
import S_stock_out from "./Componet/Summary_Stock/S_stock_out";
import S_stock_in_wastage from "./Componet/Summary_Stock/S_stock_in_wastage";
import S_stock_out_wastage from "./Componet/Summary_Stock/S_stock_out_wastage";
import S_stock_transfer from "./Componet/Summary_Stock/S_stock_transfer";
import S_stock_balance from "./Componet/Summary_Stock/S_stock_balance";


function App() {
    return (
        <>
            <BrowserRouter basename='/'>
                <Switch>
                    <Route path="/user" exact component={User}/>
                    <Route path="/objuser" exact component={ObjUser}/>
                    <Route path="/useraccess1" exact component={UserAccess1}/>
                    <Route path="/useraccess2" exact component={UserAccess2}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/" exact component={Welcome}/>
                    <Route path="/siunit" exact component={Siunit}/>
                    <Route path="/category" exact component={Category}/>
                    <Route path="/stock_item" exact component={Stock_item}/>
                    <Route path="/warehouse" exact component={Warehouse}/>
                    <Route path="/stock_in_req" exact component={Stock_in_req}/>
                    <Route path="/stock_in_app" exact component={Stock_in_app}/>
                    <Route path="/stock_in_rec" exact component={Stock_in_rec}/>
                    <Route path="/stock_in_daily" exact component={Stock_in_daily}/>
                    <Route path="/stock_out_req" exact component={Stock_out_req}/>
                    <Route path="/stock_out_app" exact component={Stock_out_app}/>
                    <Route path="/stock_out_rec" exact component={Stock_out_rec}/>
                    <Route path="/stock_out_daily" exact component={Stock_out_daily}/>
                    <Route path="/stock_wastage_in" exact component={Stock_wastage_in}/>
                    <Route path="/stock_wastage_out" exact component={Stock_wastage_out}/>
                    <Route path="/stock_transfer" exact component={Stock_transfer}/>
                    <Route path="/r_inventory_summary" exact component={R_inventory_summary}/>
                    <Route path="/r_inventory_valuation" exact component={R_inventory_valuation}/>
                    <Route path="/r_fifo_cost_tracking" exact component={R_fifo_cost_tracking}/>
                  {/*  <Route path="/r_stock_summary" exact component={R_stock_summary}/>*/}
                    <Route path="/inventory_dashboard" exact component={Inventory_dashboard}/>
                    <Route path="/transaction_d_dashboard" exact component={Transaction_d_dashboard}/>
                    <Route path="/transaction_m_dashboard" exact component={Transaction_m_dashboard}/>
                    <Route path="/balance_dashboard" exact component={Balance_dashboard}/>
                    <Route path="/warehouse_role" exact component={Warehouse_role}/>
                    <Route path="/approval_role" exact component={Approval_role}/>
                    <Route path="/r_stock_in" exact component={R_stock_in}/>
                    <Route path="/r_stock_out" exact component={R_stock_out}/>
                    <Route path="/r_stock_in_wastage" exact component={R_stock_in_wastage}/>
                    <Route path="/r_stock_out_wastage" exact component={R_stock_out_wastage}/>
                    <Route path="/r_stock_transfer" exact component={R_stock_transfer}/>
                    <Route path="/daily_in" exact component={Daily_in}/>
                    <Route path="/daily_out" exact component={Daily_out}/>
                    <Route path="/daily_transfer" exact component={Daily_transfer}/>
                    <Route path="/s_stock_in" exact component={S_stock_in}/>
                    <Route path="/s_stock_out" exact component={S_stock_out}/>
                    <Route path="/s_stock_in_wastage" exact component={S_stock_in_wastage}/>
                    <Route path="/s_stock_out_wastage" exact component={S_stock_out_wastage}/>
                    <Route path="/s_stock_transfer" exact component={S_stock_transfer}/>
                    <Route path="/s_stock_balance" exact component={S_stock_balance}/>
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;