import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import {Modal} from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
let source = axios.CancelToken.source();
ChartJS.register(ArcElement, Tooltip, Legend);
class Transaction_m_dashboard extends Component {

    constructor() {
        super();
        this.state = {
            isload:false,
            obj:null,
            type:1,
        }
    }

    componentDidMount() {
        this.fetcheddata();
    }
    fetcheddata = () => {
        this.setState({
            isload:false,
            obj:[],
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_transaction_m_dashboard;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            type:this.state.type,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    isload:true,
                    obj:res.data,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    table_stock_in = () => {
        let tabledata=null;
        if(this.state.isload){
            tabledata=this.state.obj.ins_datas.map(v=>{
                return  <tr>
                    <td>{v.name}</td>
                    <td>{v.spec}</td>
                    <td>{v.qty}</td>
                    <td>{v.price}</td>
                </tr>
            })
        }
        return (<>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th className="col-md-3">Item Name</th>
                    <th className="col-md-3">Spec</th>
                    <th className="col-md-3">Quantity</th>
                    <th className="col-md-3">Total Price</th>
                </tr>
                </thead>
                <tbody>
                {tabledata}
                </tbody>
            </table></>)
    }
    table_stock_out = () => {
        let tabledata=null;
        if(this.state.isload){
            tabledata=this.state.obj.outs_datas.map(v=>{
                return  <tr>
                    <td>{v.name}</td>
                    <td>{v.spec}</td>
                    <td>{v.qty}</td>
                    <td>{v.price}</td>
                </tr>
            })
        }
        return (<>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th className="col-md-3">Item Name</th>
                    <th className="col-md-3">Spec</th>
                    <th className="col-md-3">Quantity</th>
                    <th className="col-md-3">Total Price</th>
                </tr>
                </thead>
                <tbody>
                {tabledata}
                </tbody>
            </table></>)
    }
    table_stock_wastage = () => {
        let tabledata=null;
        if(this.state.isload){
            tabledata=this.state.obj.wastages_datas.map(v=>{
                return  <tr>
                    <td>{v.name}</td>
                    <td>{v.spec}</td>
                    <td>{v.qty}</td>
                    <td>{v.price}</td>
                    <td>{v.type}</td>
                </tr>
            })
        }
        return (<>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th className="col-md-3">Item Name</th>
                    <th className="col-md-3">Spec</th>
                    <th className="col-md-2">Quantity</th>
                    <th className="col-md-2">Total Price</th>
                    <th className="col-md-2">Type</th>
                </tr>
                </thead>
                <tbody>
                {tabledata}
                </tbody>
            </table></>)
    }
    btnfetch = (event,type) => {
        event.preventDefault()
        this.setState({
            type: type
        }, () => {
            this.fetcheddata();
        })
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    render() {
        let stock_in_count="NA"
        let stock_out_count="NA"
        let stock_wastage_count="NA"
        let chart=null;
        if(this.state.isload) {
            stock_in_count = this.state.obj.in_count;
            stock_out_count = this.state.obj.out_count;
            stock_wastage_count = this.state.obj.wastages_count;
            const data = {
                labels: ['Stock IN','Stock Out','Stock Wastage'],
                datasets: [
                    {
                        label: '',
                        data: [this.state.obj.in_price, this.state.obj.out_price, this.state.obj.wastages_price],
                        backgroundColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 99, 132, 1)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 99, 132, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            const options = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Transaction ($)',
                    },
                },
            };
            chart = <div className="chart">
                <div id="areaChart" style={{height: '350px', width: '100%'}} height={250} width={467}>
                    <Pie options={options} data={data}/>
                </div>
            </div>
        }
        return (
            <>
                <Nav type="transaction_m_dashboard"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Transaction Monthly</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Dashboard_2</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="card">
                                        <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">
                                                <i className="fas fa-chart-pie mr-1" />
                                                Transaction Summary
                                            </h3>
                                            <div className="card-tools">
                                                <ul className="nav nav-pills ml-auto">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" onClick={event => this.btnfetch(event,1)} href="" data-toggle="tab">Current</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="" onClick={event => this.btnfetch(event,2)} data-toggle="tab">Previous</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{height: '350px', width: '100%'}}>
                                            <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                <p className="text-success text-xl">
                                                    <i className="fa fa-warehouse" />
                                                </p>
                                                <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
              {stock_in_count}
            </span>
                                                    <span className="text-muted">Stock In</span>
                                                </p>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                <p className="text-warning text-xl">
                                                    <i className="fa fa-outdent" />
                                                </p>
                                                <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
                {stock_out_count}
            </span>
                                                    <span className="text-muted">Stock Out</span>
                                                </p>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                <p className="text-danger text-xl">
                                                    <i className="fa fa-trash" />
                                                </p>
                                                <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
             {stock_wastage_count}
            </span>
                                                    <span className="text-muted">Stock Wastage</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="card">
                                        <div className="card-header border-0">
                                            <h3 className="card-title">Inventory Summary Chart</h3>
                                        </div>
                                        <div className="card-body" >
                                            {chart}
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header bg-success border-0">
                                            <h3 className="card-title">STOCKS IN</h3>
                                        </div>
                                        <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                                            {this.table_stock_in()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header bg-warning border-0">
                                            <h3 className="card-title">STOCKS OUT</h3>
                                        </div>
                                        <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                                            {this.table_stock_out()}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header bg-danger border-0">
                                            <h3 className="card-title">STOCKS WASTAGE</h3>
                                        </div>
                                        <div className="card-body table-responsive" style={{height: '350px', width: '100%'}}>
                                          {this.table_stock_wastage()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Transaction_m_dashboard;
