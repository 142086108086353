import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Stock_out_req extends Component {

    constructor() {
        super();
        this.state = {
            categories: [],
            category_selected: null,

            stock_items: [],
            stock_item_selected: null,

            warehouses: [],
            main_warehouses: [],
            warehouse_selected: null,

            fileds: [],
            form_main_display: false,
            form_fileds_display: false,
            filed_1: null,
            filed_2: null,
            filed_3: null,
            filed_4: null,
            filed_5: null,
            filed_6: null,
            filed_7: null,
            filed_8: null,
            filed_9: null,
            filed_10: null,
            filed_11: null,
            filed_12: null,
            filed_13: null,
            filed_14: null,
            filed_15: null,
            stock_balance: '',
            req_qty: '',
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_warehouse_stock_out_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            }
            else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else {
                this.setState({
                    main_warehouses:res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
        baseurl = myconfig.get_stock_out_req_items;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered:res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    handelchange_category = (category_selected) => {
        this.setState({
            warehouses: [],
            warehouse_selected: null,
            stock_items:[],
            stock_item_selected:null,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_items_stock_out_req;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: category_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        stock_items: res.data.datas,
                        category_selected: category_selected,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    handelchange_stock_item = (stock_item_selected) => {
        this.setState({
            warehouses: [],
            warehouse_selected: null,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_items_fileds_stock_req;
          /*  axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: stock_item_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if(res.data.data==null){
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found for the given data",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    }
                    else {

                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });*/
            this.setState({
                warehouses:this.state.main_warehouses,
                stock_item_selected: stock_item_selected,
            })
        })


    }
    handelchange_warehouse = (warehouse_selected) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_stock_item_balance_by_stock_warehouse_stock_out;
        if (this.state.stock_item_selected != null && warehouse_selected!=null) {
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                stock_item_id: this.state.stock_item_selected.value,
                ware_house_id: warehouse_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if(res.data.data==null){
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found for the given data",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    }
                    else {
                        this.setState({
                            warehouse_selected: warehouse_selected,
                            fileds:res.data,
                            form_fileds_display:true,
                            category:res.data.category,
                            siunit:res.data.siunit,
                            number_of_filed:res.data.number_of_filed,
                        },()=>this.fetchdata_by_fileds())
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    showform = (event,iscreate,id=-1) => {
        event.preventDefault()
        this.setState({
            categories: [],
            category_selected: null,
            stock_items: [],
            stock_item_selected: null,
            name: '',
            req_qty: '',
            req_remark: '',
            category:'',
            number_of_filed:'',
            siunit:'',
            filed_1: '',
            filed_2: '',
            filed_3: '',
            filed_4: '',
            filed_5: '',
            filed_6: '',
            filed_7: '',
            filed_8: '',
            filed_9: '',
            filed_10: '',
            filed_11: '',
            filed_12: '',
            filed_13: '',
            filed_14: '',
            filed_15: '',
            form_fileds_display:false,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_category_stock_out_req;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (iscreate) {
                        this.setState({
                            cushow: true,
                            id: id,
                            iscreate: iscreate,
                            name: '',
                            categories: res.data.categories,
                            form_main_display: true,
                            type:'create'
                        })
                    }

                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    hideform = (event) => {
        event.preventDefault()
        this.setState({
            form_main_display:false,
            form_fileds_display:false,
        })
    }
    fetchdata_by_fileds = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_stock_item_balance_by_fileds_stock_out;
        console.log(this.state)
        let filed_selected_1=this.state.filed_1==null?null:this.state.filed_1.value;
        let filed_selected_2=this.state.filed_2==null?null:this.state.filed_2.value;
        let filed_selected_3=this.state.filed_3==null?null:this.state.filed_3.value;
        let filed_selected_4=this.state.filed_4==null?null:this.state.filed_4.value;
        let filed_selected_5=this.state.filed_5==null?null:this.state.filed_5.value;
        let filed_selected_6=this.state.filed_6==null?null:this.state.filed_6.value;
        let filed_selected_7=this.state.filed_7==null?null:this.state.filed_7.value;
        let filed_selected_8=this.state.filed_8==null?null:this.state.filed_8.value;
        let filed_selected_9=this.state.filed_9==null?null:this.state.filed_9.value;
        let filed_selected_10=this.state.filed_10==null?null:this.state.filed_10.value;
        let filed_selected_11=this.state.filed_11==null?null:this.state.filed_11.value;
        let filed_selected_12=this.state.filed_12==null?null:this.state.filed_12.value;
        let filed_selected_13=this.state.filed_13==null?null:this.state.filed_13.value;
        let filed_selected_14=this.state.filed_14==null?null:this.state.filed_14.value;
        let filed_selected_15=this.state.filed_15==null?null:this.state.filed_15.value;

        axios.post(baseurl, {
            sid: window.localStorage.getItem("tecnosms_sid"),
            stock_item_id: this.state.stock_item_selected.value,
            ware_house_id: this.state.warehouse_selected.value,
            filed_selected_1: filed_selected_1,
            filed_selected_2: filed_selected_2,
            filed_selected_3: filed_selected_3,
            filed_selected_4: filed_selected_4,
            filed_selected_5: filed_selected_5,
            filed_selected_6: filed_selected_6,
            filed_selected_7: filed_selected_7,
            filed_selected_8: filed_selected_8,
            filed_selected_9: filed_selected_9,
            filed_selected_10: filed_selected_10,
            filed_selected_11: filed_selected_11,
            filed_selected_12: filed_selected_12,
            filed_selected_13: filed_selected_13,
            filed_selected_14: filed_selected_14,
            filed_selected_15: filed_selected_15,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("tecnosms_sid", null);
                window.localStorage.setItem("tecnosms_username", null);
                window.localStorage.setItem("tecnosms_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    stock_balance: res.data.balance,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    showform_show = (event,id=-1) => {
        event.preventDefault()
        this.setState({
            categories: [],
            category_selected: null,
            stock_items: [],
            stock_item_selected: null,
            name: '',
            req_qty: '',
            req_remark: '',
            category: '',
            number_of_filed: '',
            siunit: '',
            filed_1: '',
            filed_2: '',
            filed_3: '',
            filed_4: '',
            filed_5: '',
            filed_6: '',
            filed_7: '',
            filed_8: '',
            filed_9: '',
            filed_10: '',
            filed_11: '',
            filed_12: '',
            filed_13: '',
            filed_14: '',
            filed_15: '',
            stock_balance:'',
            form_main_display:false,
            form_fileds_display:false,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_stock_out_req_item;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null)
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found for given id",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    else
                        this.setState({
                            req_qty: res.data.data.req_qty,
                            req_remark: res.data.data.req_remark,
                            show_category: res.data.data.show_category,
                            show_stock_item: res.data.data.show_stock_item,
                            show_ware_house: res.data.data.show_ware_house,
                            category: res.data.data.show_category,
                            number_of_filed: res.data.data.number_of_filed,
                            obj:res.data.sitem,
                            siunit: res.data.data.stockin.siunit,
                            stock_balance: res.data.balance,
                            filed_1: res.data.data.stockin.filed_1,
                            filed_2: res.data.data.stockin.filed_2,
                            filed_3: res.data.data.stockin.filed_3,
                            filed_4: res.data.data.stockin.filed_4,
                            filed_5: res.data.data.stockin.filed_5,
                            filed_6: res.data.data.stockin.filed_6,
                            filed_7: res.data.data.stockin.filed_7,
                            filed_8: res.data.data.stockin.filed_8,
                            filed_9: res.data.data.stockin.filed_9,
                            filed_10: res.data.data.stockin.filed_10,
                            filed_11: res.data.data.stockin.filed_11,
                            filed_12: res.data.data.stockin.filed_12,
                            filed_13: res.data.data.stockin.filed_13,
                            filed_14: res.data.data.stockin.filed_14,
                            filed_15: res.data.data.stockin.filed_15,
                            type: 'show',
                            form_main_display: true,
                            form_fileds_display: true,
                        })
                }
            }).catch((e) => {
                console.log(e.message);
            });

        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    form_stock_req = () => {
        let retdata = null;

        if(this.state.form_main_display){
            let isDisabled=false
            let sec_1=<>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>Category </label>
                        <Select
                            isDisabled={isDisabled}
                            value={this.state.category_selected}
                            onChange={this.handelchange_category}
                            options={this.state.categories}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>Stock Item</label>
                        <Select
                            isDisabled={isDisabled}
                            value={this.state.stock_item_selected}
                            onChange={this.handelchange_stock_item}
                            options={this.state.stock_items}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>Warehouse</label>
                        <Select
                            isDisabled={isDisabled}
                            value={this.state.warehouse_selected}
                            onChange={this.handelchange_warehouse}
                            options={this.state.warehouses}
                        />
                    </div>
                </div>
            </>
            let header='New Stock OUT Request';
            let button_sub= <form action="#" method="post">
                <button onClick={this.btncreate} type="button" className="btn btn-block btn-primary">Stock OUT Request
                </button>
            </form>
            if(this.state.type=="show"){
                header='Show Stock OUT Request';
                isDisabled=true
                button_sub=null;
                sec_1=<>
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Category </label>
                            <Select
                                isDisabled={isDisabled}
                                value={this.state.category_selected}
                                onChange={this.handelchange_category}
                                options={this.state.categories}
                                placeholder={this.state.show_category}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Stock Item</label>
                            <Select
                                isDisabled={isDisabled}
                                value={this.state.stock_item_selected}
                                onChange={this.handelchange_stock_item}
                                options={this.state.stock_items}
                                placeholder={this.state.show_stock_item}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>Warehouse</label>
                            <Select
                                text={"Fasica"}
                                isDisabled={isDisabled}
                                value={this.state.warehouse_selected}
                                onChange={this.handelchange_warehouse}
                                options={this.state.warehouses}
                                placeholder={this.state.show_ware_house}
                            />
                        </div>
                    </div>
                </>
            }
            retdata = <div className="col-12">
                <div className="card">
                    <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                        <h3 className="card-title">{header}</h3>
                        <div className="card-tools">
                            <button type="button" onClick={this.hideform} className="btn btn-tool">
                                <i className="fas fa-times"/>
                            </button>
                        </div>
                    </div>
                    <form>
                        <div className="card-body">
                            <div className="row">
                                {sec_1}
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input disabled={true} className="form-control" type="text" name="category" onChange={this.inputmgt} value={this.state.category}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Unit of Measurement</label>
                                            <input disabled={true} className="form-control" type="text" name="siunit" onChange={this.inputmgt} value={this.state.siunit}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label># of Fields</label>
                                            <input disabled={true} className="form-control" type="text" name="number_of_filed" onChange={this.inputmgt} value={this.state.number_of_filed}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea disabled={isDisabled}  onChange={this.inputmgt} rows={5} className="form-control"
                                                  name="req_remark" value={this.state.req_remark}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                </div>
                                <div className="col-md-4 col-sm-6">
                                </div>
                                {this.form_stock_req_fields()}
                            </div>
                        </div>
                    </form>
                    {button_sub}
                </div>
            </div>
        }
        return (<>{retdata}</>)
    }
    form_stock_req_fields2 = () => {
        let retdata = null;
        if(this.state.form_fileds_display) {
            let isDisabled=false;
            if(this.state.type=="show"){
                isDisabled=true;
            }
            let arr=[];
            for(let i=0; i<this.state.number_of_filed; i++){
                arr.push(i+1)
            }
            let d=arr.map(v=>{
                let t="text";
                if(this.state.obj["filed_type_"+v]=="NUMERIC")
                    t="number"
                return (
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label>{this.state.obj["filed_name_"+v]}</label>
                            <div className="input-group mb-3">
                                <input disabled={isDisabled} type={t} onChange={this.inputmgt} value={this.state["filed_"+v]} name={"filed_"+v} className="form-control"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">{this.state.obj["filed_siunit_"+v]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
            retdata = <>
                {d}
            </>
        }
        return (<>{retdata}</>)
    }
    form_stock_req_fields = () => {
        let retdata = null;
        if (this.state.form_fileds_display) {
            if(this.state.type=="show") {
                let arr = [];
                for (let i = 0; i < this.state.number_of_filed; i++) {
                    arr.push(i + 1)
                }
                let d = arr.map(v => {
                    return (
                        <div className="col-md-4 col-sm-6">
                            <div className="form-group">
                                <label>{this.state.obj["filed_name_" + v]}</label>
                                <div className="input-group mb-3">
                                    <input disabled={true} type="text" value={this.state["filed_" + v]}
                                           className="form-control"/>
                                    <div className="input-group-append">
                                        <span className="input-group-text">{this.state.obj["filed_siunit_" + v]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                });
                retdata = <>
                    {d}
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <div className="form-group">
                                <label>Stock Balance</label>
                                <input disabled={true} className="form-control" type="text" name="stock_balance" onChange={this.inputmgt} value={this.state.stock_balance}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <div className="form-group">
                                <label>Request Balance</label>
                                <input disabled={true} className="form-control" type="text" name="req_qty" onChange={this.inputmgt} value={this.state.req_qty}/>
                            </div>
                        </div>
                    </div>
                </>;
            }
            else
            {
                let d = this.state.fileds.data.labels.map(v => {
                    return (
                        <div className="col-md-4 col-sm-6">
                            <div className="form-group">
                                <label>{v.label}</label>
                                <Select
                                    id={this.state[v.variable]}
                                    value={this.state[v.variable]}
                                    onChange={sv => {
                                        this.setState({
                                            [v.variable]: sv,
                                        },()=>this.fetchdata_by_fileds())
                                    }}
                                    options={this.state.fileds[v.variable]}
                                />
                            </div>
                        </div>
                    )
                });
                retdata = <>
                    {d}
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <div className="form-group">
                                <label>Stock Balance</label>
                                <input disabled={true} className="form-control" type="text" name="stock_balance" onChange={this.inputmgt} value={this.state.stock_balance}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <div className="form-group">
                                <label>Request Balance</label>
                                <input className="form-control" type="text" name="req_qty" onChange={this.inputmgt} value={this.state.req_qty}/>
                            </div>
                        </div>
                    </div>
                </>
            }

        }
        return (<>{retdata}</>)
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    btncreate = (event) => {
        event.preventDefault()
        if (this.state.stock_item_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Stock Item is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.warehouse_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Warehouse is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.create_stock_out_req;
            let filed_selected_1 = this.state.filed_1 == null ? null : this.state.filed_1.value;
            let filed_selected_2 = this.state.filed_2 == null ? null : this.state.filed_2.value;
            let filed_selected_3 = this.state.filed_3 == null ? null : this.state.filed_3.value;
            let filed_selected_4 = this.state.filed_4 == null ? null : this.state.filed_4.value;
            let filed_selected_5 = this.state.filed_5 == null ? null : this.state.filed_5.value;
            let filed_selected_6 = this.state.filed_6 == null ? null : this.state.filed_6.value;
            let filed_selected_7 = this.state.filed_7 == null ? null : this.state.filed_7.value;
            let filed_selected_8 = this.state.filed_8 == null ? null : this.state.filed_8.value;
            let filed_selected_9 = this.state.filed_9 == null ? null : this.state.filed_9.value;
            let filed_selected_10 = this.state.filed_10 == null ? null : this.state.filed_10.value;
            let filed_selected_11 = this.state.filed_11 == null ? null : this.state.filed_11.value;
            let filed_selected_12 = this.state.filed_12 == null ? null : this.state.filed_12.value;
            let filed_selected_13 = this.state.filed_13 == null ? null : this.state.filed_13.value;
            let filed_selected_14 = this.state.filed_14 == null ? null : this.state.filed_14.value;
            let filed_selected_15 = this.state.filed_15 == null ? null : this.state.filed_15.value;

            axios.post(baseurl, {
                sid: window.localStorage.getItem("tecnosms_sid"),
                stock_item_id: this.state.stock_item_selected.value,
                ware_house_id: this.state.warehouse_selected.value,
                filed_1: filed_selected_1,
                filed_2: filed_selected_2,
                filed_3: filed_selected_3,
                filed_4: filed_selected_4,
                filed_5: filed_selected_5,
                filed_6: filed_selected_6,
                filed_7: filed_selected_7,
                filed_8: filed_selected_8,
                filed_9: filed_selected_9,
                filed_10: filed_selected_10,
                filed_11: filed_selected_11,
                filed_12: filed_selected_12,
                filed_13: filed_selected_13,
                filed_14: filed_selected_14,
                filed_15: filed_selected_15,
                req_qty:this.state.req_qty,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("tecnosms_sid", null);
                    window.localStorage.setItem("tecnosms_username", null);
                    window.localStorage.setItem("tecnosms_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(value => {
                        this.fetcheddata()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btndelete = (event,id) => {
        event.preventDefault()
        Swal.fire({
            title: 'Are you sure you want to delete the stock out request? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let baseurl = myconfig.delete_stock_out_req;
                let redirecttologin = myconfig.redirecttologin;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("tecnosms_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("tecnosms_sid", null);
                        window.localStorage.setItem("tecnosms_username", null);
                        window.localStorage.setItem("tecnosms_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(value => {
                            this.fetcheddata()
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            let del=null;
            retdata = this.state.filtered.map(value => {
                count=count+1;
                if (this.state.objs.usermatrix.update == true)
                    del =<td className="col-md-1"><a href="" onClick={event => this.btndelete(event,value.id)}>Delete</a></td>
                return (<tr>
                    <td className="col-md-1">{count}</td>
                    <td className="col-md-1"><a href="" onClick={event => this.showform_show(event,value.id)}>Show</a></td>
                    {del}
                    <td className="col-md-1">{value.cateory.name}</td>
                    <td className="col-md-1">{value.stockitem.name}</td>
                    <td className="col-md-1">{value.warehouse.name}</td>
                    <td className="col-md-1">{value.warehouse.location}</td>
                    <td className="col-md-1">{value.req_by}</td>
                    <td className="col-md-1">{value.req_at}</td>
                    <td className="col-md-1">{value.req_qty}</td>
                    <td className="col-md-1">{value.siunit.name}</td>

                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    render() {
        let del=null;
        if(this.state.objsload) {
            if (this.state.objs.usermatrix.update == true)
                del =<th className="col-md-1">Delete</th>
        }
        let create=null;
        if(this.state.objsload) {
            if (this.state.objs.usermatrix.create == true)
                create =    <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}>
                        <button className="btn btn-outline-primary btn-block" onClick={(event => this.showform(event,true))}>New Request</button>
                    </div>
                </div>
        }
        return (
            <>
                <Nav type="stock_out_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Stock OUT Request</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Request</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {this.form_stock_req()}
                                    <div className="card">
                                     {/*   <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right" value={this.state.txtsearch} onChange={this.inputsearch} placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            {create}
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-1">#</th>
                                                    <th className="col-md-1">Show</th>
                                                    {del}
                                                    <th className="col-md-1">Category</th>
                                                    <th className="col-md-1">Item Name</th>
                                                    <th className="col-md-1">Warehouse</th>
                                                    <th className="col-md-1">Location</th>
                                                    <th className="col-md-1">By</th>
                                                    <th className="col-md-1">At</th>
                                                    <th className="col-md-1">Quantity</th>
                                                    <th className="col-md-1">Unit</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Stock_out_req;
